import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

function AccessDenied() {
    const navigate = useNavigate();
    const currentUser = useSelector((state) => (state.auth.user));

    useEffect(() => {
        if (!currentUser)
            navigate('/loading', { state: { redirectPath: window.location.pathname }});
    }, [])

    return (
        <React.Fragment>
            TODO: implement a proper AccessDenied screen.
        </React.Fragment>
    );
}

export default AccessDenied;