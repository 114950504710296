import React from 'react';

function Tab(props) {
    const tabClass = props.active ? 'active-tab' : 'tab';

    const handleOnTabClick = () => {
        props.onTabClick(props.name);
    };

    return (
        <div className={tabClass} onClick={handleOnTabClick}>
            <p>{props.name}</p>
            <hr />
        </div>
    );
}

export default Tab;