import { DateTime } from 'luxon';

export const dateFormats = {
    'en-gb': 'dd/MM/yyyy',
    'full-en-gb': 'hh:mm:ss dd/MM/yyyy',
    'en-us': 'MM/dd/yyyy',
    'zh-cn': 'yyyy/MM/dd',
    'de': 'dd.MM.yyyy',
}

export function parseDate(date) {
    if (!date) return date;

    try {
        let parsedDate = DateTime.fromISO(date);
        if (!parsedDate.isValid) parsedDate = DateTime.fromRFC2822(date);
        for (const format in dateFormats) {
            if (parsedDate.isValid) break;
            parsedDate = DateTime.fromFormat(date, dateFormats[format]);
        }

        return parsedDate;
    } catch (error) {
        console.log(`parseDate: Error with date: ${date}`, error); // TODO: Remove the try catch once the method is stable
        return date;
    }
}

// TODO: add a second parameter for specifying the format
export function formatDate(date, format = dateFormats['en-gb']) {
    if (!date) return date;

    try {
        return parseDate(date).toFormat(format)
    } catch (error) {
        console.log(`formatDate: Error with date: ${date}`, error); // TODO: Remove the try catch once the method is stable
        return date;
    }
}