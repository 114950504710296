import React, { useState } from 'react';

import './Products.scss';
import sampleImage from '../../assets/sample-image.svg';

import Product from './Product';

const DEFAULT_PRODUCTS = [
    {
        title: 'Carbon Ally Plataform',
        summary: [],
    },
    {
        title: 'Mitigation Oportunities',
        summary: [
            'Register for selling or buying carbon offsets',
            'Register for selling or buying Inflation Reduction Act (IRA Tax Credits)',
        ],
        description: "With CarbonAlly Trading Platform you can sell your own or procure high quality Carbon Offsets. In addition, you can also sell of procure Inflation Reduction Act Tax Credits.",
        price: 'USD TBD/year',
        img: sampleImage,
    },
    {
        title: 'Smart Contracts',
        summary: [
            'To be developed'
        ],
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus. \nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus.",
        price: 'USD 50/year',
        img: sampleImage,
    }
]

function Products() {
    const [selectedProduct, setSelectedProduct] = useState('');

    const handleOnSelectProduct = (title) => {
        setSelectedProduct(title);
    }

    return (
        <React.Fragment>
            <div className="products-list">
                {DEFAULT_PRODUCTS.map((product) => (
                    <Product
                        selected={product.title === selectedProduct}
                        title={product.title}
                        summary={product.summary}
                        description={product.description}
                        price={product.price}
                        img={product.img}
                        key={product.title}
                        onSelectProduct={handleOnSelectProduct}
                    />
                ))}
            </div>
            <div id='selected-product-details' />
        </React.Fragment>
    );
}

export default Products;