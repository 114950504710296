import React from 'react';

function TaxCredits() {
    return (
        <React.Fragment>
            <div className="tax-credits-container">
                Under development
            </div>
        </React.Fragment>
    );
}

export default TaxCredits;