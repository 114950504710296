import React from 'react';
import { Link } from 'react-router-dom';

import './NavBarTab.scss';
import arrow_up from '../../assets/icons/arrow_up.svg';
import arrow_down from '../../assets/icons/arrow_down.svg';

import Icon from '../UI/Icon';

function NavBarTab(props) {
    const tabContent = (
        <div className="nav-tab-content">
            <Icon src={props.icon} />
            <p>{props.name}</p>
        </div>
    );

    if (props.isParent) {
        return (
            <li
                className={`nav-bar-tab${props.isActive ? ' active' : ''}`}
                onClick={props.onClick}
            >
                {tabContent}
                <Icon
                    src={props.childrenShown ? arrow_up : arrow_down}
                    className='nav-bar-tab-arrow'
                />
            </li>
        );
    }

    return (
        <Link to={props.link}>
            <li className={`nav-bar-tab${props.isActive ? ' active' : ''}`}>
                {tabContent}
            </li>
        </Link>
    );
}

export default NavBarTab;