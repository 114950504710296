import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import './Project.scss';
import left_arrow from '../../assets/icons/left_arrow.svg';

import Button from '../UI/Button';
import Icon from '../UI/Icon';
import Tabs from '../UI/Tabs/Tabs';

import useAxios from '../../hooks/use-axios';
import ScopesSection from './Sections/ScopesSection';
import InventoryDataSection from './Sections/InventoryDataSection';
import OperationalBoundariesSection from './Sections/OperationalBoundariesSection';
import OrganizationalLimitsSection from './Sections/OrganizationalLimitsSection';
import ChartsSection from './Sections/ChartsSection';
import ResultsSection from './Sections/ResultsSection';

import { projectsActions } from '../../store/projects-slice';
import { serializeAsBrackets } from '../../tools/params-serializers';

// TODO: Fetch GHG Inventory Structure if for some reason it is not already stored
function Project() {
    const dispatch = useDispatch();
    const params = useParams();
    const project = useSelector((state) => state.projects.currentProject);
    const { sendRequest: fetchProject } = useAxios();
    const { isLoading, error, sendRequest: updateProject } = useAxios(); // TODO: show loading indicator next to prject name while saving project

    useEffect(() => {
        const projectRequest = {
            url: `/projects/${params.id}`,
        };
        fetchProject(projectRequest, (data) => {
            dispatch(projectsActions.setCurrentProject(data.project));
        });
    }, [fetchProject]);

    const saveProject = () => {
        const updateProjectRequest = {
            url: `/projects/${params.id}/update_ghg_inventory`,
            method: 'post',
            params: {
                ghg_inventory_json: project.ghg_inventory
            },
            paramsSerializer: { 
                serialize: serializeAsBrackets
            },
            manageResponse: true
        };
        updateProject(updateProjectRequest, (data) => {
            // TODO: Add logic for showing the project was saved
        });
    };

    if (!project || project.id != params.id) { // TODO: improve the loading screen
        return (
            <div>Loading...</div>
        );
    }

    const tabs = [
        { name: 'Scopes', content: <ScopesSection /> },
        { name: 'Inventory Data', content: <InventoryDataSection /> },
        { name: 'Organizational Limits', content: <OrganizationalLimitsSection /> },
        { name: 'Operational Boundaries ', content: <OperationalBoundariesSection /> },
        { name: 'Charts', content: <ChartsSection /> },
        { name: 'Results', content: <ResultsSection /> },
    ]

    return (
        <React.Fragment>
            <div className="inventory-header">
                <div className="inventory-title">
                    <Link to='/inventories'>
                        <p className="back-to-inventories"><Icon src={left_arrow} />Inventories</p>
                    </Link>
                    <p className="inventory-name">{project.name}</p>
                </div>
                <Button className="save-inventory custom-button" onClick={saveProject}>Save</Button>
            </div>
            <div className="inventory-sections-container">
                <Tabs tabs={tabs} contentContainerClasses="inventory-section-container" />
            </div>
        </React.Fragment>
    );
}

export default Project;