import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './Users.scss';

import UsersTable from './UsersTable';
import UsersFilters from './UsersFilters';
import AddUser from './AddUser';

import useAxios from '../../hooks/use-axios';

import { usersActions } from '../../store/users-slice';
import { getArrayPage, totalPages } from '../../tools/pagination-helper';

function Users() {
    const dispatch = useDispatch();
    const users = useSelector((state) => state.users.users);
    const [companies, setCompanies] = useState([]);
    const [page, setPage] = useState(1);
    const [filters, setFilters] = useState({ keyword: '', company: '', role: '' });
    const { isLoading, error, sendRequest: fetchUsers } = useAxios();
    const perPage = 5; // TODO: make this field configurable and store it in the store

    useEffect(() => {
        const usersIndexRequest = {
            url: '/users_index',
            params: {
                filters,
            }
        };
        fetchUsers(usersIndexRequest, (data) => {
            dispatch(usersActions.setUsers(data.users));
            setCompanies(data.companies);
        });
    }, [fetchUsers, filters]);

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    const handleFiltersChange = (newFilters) => {
        setFilters((oldFilters) => ({ ...oldFilters, ...newFilters }));
    }

    return (
        <React.Fragment>
            <AddUser companies={companies} />
            <UsersFilters
                onFiltersChange={handleFiltersChange}
                companies={companies}
            />
            <UsersTable
                users={getArrayPage(users, page, perPage)}
                loading={isLoading}
                error={error}
                page={page}
                pages={totalPages(users.length, perPage)}
                onPageChange={handlePageChange}
            />
        </React.Fragment>
    )
}

export default Users;