import { createSlice } from '@reduxjs/toolkit';

const initialCompaniesState = {
    companies: [],
};
const companiesSlice = createSlice({
    name: 'Companies',
    initialState: initialCompaniesState,
    reducers: {
        setCompanies(state, action) {
            state.companies = action.payload;
        },
        addCompany(state, action) {
            state.companies.push(action.payload);
        },
        updateCompany(state, action) {
            state.companies = state.companies.map((company) => {
                if (company.id === action.payload.id)
                    return action.payload;
                return company;
            });
        },
    }
});

export const companiesActions = companiesSlice.actions;
export default companiesSlice.reducer;