import React from 'react';
import Pagination from '@mui/material/Pagination';

import circle_check from '../../assets/icons/circle_check.svg';
import warning from '../../assets/icons/warning.svg';
import eye from '../../assets/icons/eye.svg';
import pencil from '../../assets/icons/pencil.svg';

import Table from '../UI/Data/Table';
import Icon from '../UI/Icon';

const statusRender = (is_enabled) => {
    if (is_enabled) return <Icon src={circle_check} />;

    return <Icon src={warning} />;
};

const columns = [
    { key: 'name', name: 'Name' },
    { key: 'title', name: 'Title' },
    { key: 'role', name: 'CarbonAlly Role' },
    { key: 'email', name: 'Email' },
    { key: 'company_name', name: 'Company' },
    { key: '$show', name: '', icon: eye, path: '/users/:id' },
    { key: '$edit', name: '', icon: pencil, path: '/users/:id/edit' },
    { key: 'is_enabled', name: '', render: statusRender },
];

function UsersTable(props) {
    return (
        <div className="users-table">
            <Table rows={props.users} columns={columns} />
            <br></br>
            <Pagination
                count={props.pages}
                page={props.page}
                onChange={props.onPageChange}
                shape="rounded"
            />
        </div>
    );
}

export default UsersTable;