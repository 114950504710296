import React, { useState } from 'react';

function TextArea(props) {
    const [value, setValue] = useState(props.value || '');
    const classes = 'custom-text-area ' + props.className;

    if (props.debouncingTime && props.onChange) {
        useEffect(() => {
            const id = setTimeout(() => {
                props.onChange(value);
            }, props.debouncingTime);
            return () => {
                clearTimeout(id);
            };
        }, [value]);
    }

    const valueChangeHandler = (event) => {
        let value = event.target.value;
        if (props.validationFunction) {
            value = props.validationFunction(value);
        }
        setValue(value);
    };

    return (
        <React.Fragment>
            <textarea
                id={props.id}
                name={props.name}
                rows={props.rows || '9'}
                cols={props.cols}
                maxLength={props.maxLength || '-1'}
                className={classes}
                placeholder={props.placeholder}
                onChange={valueChangeHandler}
                ref={props.inputRef}
                disabled={props.disabled}
                value={value}
            />
        </React.Fragment>
    );
}

export default TextArea;