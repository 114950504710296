import React from 'react';

import Modal from '../../../UI/Containers/Modal';

function DefaultIPCCValuesModal(props) {
    return (
        <Modal
            onDismiss={props.onDismiss}
            footer={<React.Fragment />}
        >
            <div className="standard-ghg-body">
                <p className="introduction-header">
                    Default IPCC ValuesDefault IPCC Values
                </p>
                <ul>
                    <li className="bullet-list-item">
                        Table 2. Default Assumptions from IPCC Good Practice Guidelines*
                    </li>
                </ul>
                <table className="standard-ghg-table">
                    <tbody>
                        <tr>
                            <th>Application</th>
                            <th>Charge (kg)</th>
                            <th>Lifetime</th>
                            <th>Assembly</th>
                            <th>Annual Leakage Rate</th>
                            <th>Recycling Efficiency</th>
                        </tr>
                        <tr>
                            <td className="standard-ghg-table-first-td">Domestic Refrigeration</td>
                            <td>0.05 - 0.5</td>
                            <td>12 - 15</td>
                            <td>0.2 - 1 %</td>
                            <td>0.1 - 0.5 %</td>
                            <td>70% of remainder</td>
                        </tr>
                        <tr>
                            <td className="standard-ghg-table-first-td">Stand-Alone Commercial Applications</td>
                            <td>0.2 - 6</td>
                            <td>8 - 12</td>
                            <td>0.5 - 3%</td>
                            <td>1 - 10 %</td>
                            <td>70 - 80% of remainder</td>
                        </tr>
                        <tr>
                            <td className="standard-ghg-table-first-td">Medium and Large Commercial Refrigeration</td>
                            <td>50 - 2000</td>
                            <td>7 - 10</td>
                            <td>0.5 - 3 %</td>
                            <td>10 - 30 %</td>
                            <td>80 - 90% of remainder</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </Modal>
    );
}

export default DefaultIPCCValuesModal;