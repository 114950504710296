import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import './AddOffsettingProject.scss';

import Button from '../UI/Button';
import Modal from '../UI/Containers/Modal';
import OffsettingProjectForm from './OffsettingProjectForm';
import SuccessModal from './SuccessModal';

import useBoolean from '../../hooks/use-boolean';

import { offsettingProjectsActions } from '../../store/offsetting-projects-slice';

function AddOffsettingProject() {
    const dispatch = useDispatch();
    const newestOffsettingProject = useSelector((state) => (state.offsettingProjects.newestOffsettingProject));
    const { boolean: isModalOpen, setTrue: openModal, setFalse: closeModal } = useBoolean();
    const { boolean: isSuccessModalOpen, setTrue: openSuccessModal, setFalse: closeSuccessModal } = useBoolean();

    const handleOnSuccess = (offsettingProject) => {
        dispatch(offsettingProjectsActions.addCompanyOffsettingProject(offsettingProject));
        closeModal();
        openSuccessModal();
    };

    const handleOnCloseForSuccessModal = () => {
        closeSuccessModal();
    };
    
    return (
        <div className="add-offsetting--project">
            {isModalOpen && 
            <Modal
                title="Add a new project"
                width={40}
                onDismiss={closeModal}
                footer={<React.Fragment />}
            >
                <OffsettingProjectForm
                    onSuccess={handleOnSuccess}
                />
            </Modal>}
            {isSuccessModalOpen && (
                <SuccessModal
                    onDismiss={handleOnCloseForSuccessModal}
                    project={newestOffsettingProject}
                />
            )}
            <Button onClick={openModal}>Add a new project</Button>
        </div>
    );
}

export default AddOffsettingProject;