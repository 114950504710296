import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import './Login.scss';

import Card from '../UI/Containers/Card';

import useAxios from '../../hooks/use-axios';
import { authActions } from '../../store/auth-slice';
import { GHGInventoryActions } from '../../store/ghg-inventory-slice';

function LoginForm() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const emailRef = useRef();
    const passwordRef = useRef();
    const { error, sendRequest: login } = useAxios();
    const { sendRequest: fetchGHGInventoryStructure } = useAxios();

    const submitLogin = async (event) => {
        event.preventDefault();
        const submitLoginRequest = {
            method: 'post',
            url: '/login',
            params: { 
                email: emailRef.current.value,
                password: passwordRef.current.value
            }
        };

        login(submitLoginRequest, (loginData) => {
            localStorage.setItem('userID', loginData.user.id);
            dispatch(authActions.saveUser(loginData.user));
           
            const GHGInventoryStructureRequest = {
                url: '/ghg_inventory_structure'  
            };
            fetchGHGInventoryStructure(GHGInventoryStructureRequest, (data) => {
                dispatch(GHGInventoryActions.saveGHGInventoryStructure(data));
            });

            navigate('/loading');
        });
    };

    return (
        <Card className="transparent-card">
            <p className="title">Sign in</p>
            <form onSubmit={submitLogin}>
                <div className="login-page__controls">
                    <div className="login-page__control">
                        <label>E-mail</label>
                        <input
                            type="text"
                            name="email"
                            ref={emailRef}
                        />
                    </div>
                    <div className="login-page__control">
                        <label>Password</label>
                        <input
                            type="password"
                            name="password"
                            ref={passwordRef}
                        />
                        <p className="login-error-message">{error}</p>
                    </div>
                </div>
                <div className="login-page__actions">
                    <button type='submit'>LOG IN</button>
                </div>
            </form>
        </Card>
    );
}

export default LoginForm;