import React from 'react';

function OperationalBoundariesSection(props) {
    return (
        <React.Fragment>
            Under development
        </React.Fragment>
    );
}

export default OperationalBoundariesSection;