import React from 'react';

import Input from './Input';

import chooseValidationFunction from '../../../tools/input-validators';

function NumberInput(props) {
    return (
        <Input
            id={props.id}
            type="text"
            placeholder={props.placeholder || ''}
            debouncingTime={props.debouncingTime || null}
            onChange={props.onChange}
            icon={props.icon || null}
            className={props.className}
            inputRef={props.inputRef}
            value={props.value}
            disabled={props.disabled}
            validationFunction={chooseValidationFunction(props.category)}
        />
    );
}

export default NumberInput;