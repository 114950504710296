import React from 'react';
import Pagination from '@mui/material/Pagination';

import circle_check from '../../assets/icons/circle_check.svg';
import warning from '../../assets/icons/warning.svg';
import eye from '../../assets/icons/eye.svg';
import pencil from '../../assets/icons/pencil.svg';

import Table from '../UI/Data/Table';
import Icon from '../UI/Icon';

const statusRender = (is_enabled) => {
    if (is_enabled) return <Icon src={circle_check} />;

    return <Icon src={warning} />;
};

const columns = [
    { key: 'name', name: 'Name' },
    { key: 'website', name: 'Website' },
    { key: 'address', name: 'Address' },
    { key: 'activity', name: 'Activity' },
    { key: '$show', name: '', icon: eye, path: '/companies/:id' },
    { key: '$edit', name: '', icon: pencil, path: '/companies/:id/edit' },
    { key: 'is_enabled', name: '', render: statusRender },
];

function CompaniesTable(props) {
    return (
        <div className="companies-table">
            <Table rows={props.companies} columns={columns} />
            <br></br>
            <Pagination
                count={props.pages}
                page={props.page}
                onChange={props.onPageChange}
                shape="rounded"
            />
        </div>
    );
}

export default CompaniesTable;