import { createSlice } from '@reduxjs/toolkit';

const initialGHGInventoryState = {
    scope1: null,
    scope2: null,
    scope3: null,
    questionTypes: null,
    componentTypes: null,
};
const GHGInventorySlice = createSlice({
    name: 'GHGInventory',
    initialState: initialGHGInventoryState,
    reducers: {
        saveGHGInventoryStructure(state, action) {
            state.scope1 = action.payload.scope_1;
            state.scope2 = action.payload.scope_2;
            state.scope3 = action.payload.scope_3;
            state.questionTypes = action.payload.question_types;
            state.componentTypes = action.payload.component_types;
        },
    }
});

export const GHGInventoryActions = GHGInventorySlice.actions;
export default GHGInventorySlice.reducer;