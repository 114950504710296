import { configureStore } from '@reduxjs/toolkit';

import authReducer from './auth-slice';
import constantsReducer from './constants-slice';
import GHGInventoryReducer from './ghg-inventory-slice';
import notificationsReducer from './notifications-slice';
import uiReducer from './ui-slice';
import projectsSliceReducer from './projects-slice';
import usersSliceReducer from './users-slice';
import offsettingProjectsSliceReducer from './offsetting-projects-slice';
import companiesSliceReducer from './companies-slice';

const store = configureStore({
    reducer: {
        auth: authReducer,
        constants: constantsReducer,
        notifications: notificationsReducer,
        ui: uiReducer,
        GHGInventory: GHGInventoryReducer,
        projects: projectsSliceReducer,
        users: usersSliceReducer,
        offsettingProjects: offsettingProjectsSliceReducer,
        companies: companiesSliceReducer,
    }
});

export default store;