import React, { useState } from "react";
import "./Login.scss";

import Card from '../UI/Containers/Card';

function RegisterForm() {
    const [email, setEmail] = useState('');
    const [country, setCountry] = useState('');
    const [city, setCity] = useState('');
    const [postalCode, setPostalCode] = useState('');

    const emailChangeEvent = (event) => {
        setEmail(event.target.value);
    }

    const countryChangeEvent = (event) => {
        setCountry(event.target.value);
    }

    const cityChangeEvent = (event) => {
        setCity(event.target.value);
    }

    const postalCodeChangeEvent = (event) => {
        setPostalCode(event.target.value);
    }

    const submitRegister = (event) => {
        event.preventDefault();

        const registerData = {
            email,
            country,
            city,
            postalCode
        };
    };

    return (
        <Card className="transparent-card">
            <p className="title">Register</p>
            <form onSubmit={submitRegister}>
                <div className="login-page__controls">
                    <div className="login-page__control">
                        <label>E-mail</label>
                        <input type="text" onChange={emailChangeEvent} />
                    </div>
                    <div className="login-page__control">
                        <label>Country</label>
                        <input type="text" onChange={countryChangeEvent} />
                    </div>
                    <div className="login-page__small-control">
                        <label>City</label>
                        <input type="text" onChange={cityChangeEvent} />
                    </div>
                    <div className="login-page__small-control">
                        <label>Postal Code</label>
                        <input type="text" onChange={postalCodeChangeEvent} />
                    </div>
                </div>
                <div className="login-page__actions">
                    <button type='submit' style={{ backgroundColor: '#284345' }}>REGISTER</button>
                </div>
            </form>
        </Card>
    );
}

export default RegisterForm;