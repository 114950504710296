import React from 'react';
import { useSelector } from 'react-redux';

import lens from '../../assets/icons/lens.svg';

import Label from '../UI/Text/Label';
import TextInput from '../UI/Inputs/TextInput';

function CompaniesFilters(props) {
    const roles = useSelector((state) => state.constants.roles);

    const onKeywordEntered = (keyword) => {
        props.onFiltersChange({ keyword });
    }

    return (
        <div className="companies-filters">
            <div className="companies-filter">
                <Label htmlFor="keyword-filter">Keyword</Label>
                <TextInput
                    id="keyword-filter"
                    placeholder="Type a keyword"
                    debouncingTime={800}
                    onChange={onKeywordEntered}
                    icon={lens}
                />
            </div>
        </div>
    );
}

export default CompaniesFilters;