/*
    Returns the flattened 'array' based on 'key'.
    E.g.:
        array = [{ a: 1, b: [{ a: 2}, { a: 3 }]}]
        key = b
        result -> [{a: 1 }, { a: 2}, { a: 3}]
*/
export function flattenArrayBasedOn(array, key) {
    const flattenedArray = [];
    for (const item of array)
        if (item[key])
            flattenedArray.push(...item[key]);
        else
            flattenedArray.push(item);

    return flattenedArray;
}