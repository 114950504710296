import React from 'react';

import pencil from '../../assets/icons/pencil.svg';

import Modal from '../UI/Containers/Modal';
import TaskForm from './TaskForm';
import Button from '../UI/Button';
import Icon from '../UI/Icon';

import useBoolean from '../../hooks/use-boolean';
import useNotification from '../../hooks/use-notification';

function EditTask(props) {
    const { pushNotification } = useNotification();
    const { boolean: isModalOpen, setTrue: openModal, setFalse: closeModal } = useBoolean();

    const handleOnSuccess = (_taskID, taskTitle) => {
        closeModal();
        pushNotification({ message: `The task ${taskTitle} has been updated` });
        props.onSuccess();
    };

    return (
        <React.Fragment>
            {isModalOpen && 
            <Modal
                title={`${props.categoryName} - Edit task`}
                onDismiss={closeModal}
                footer={<React.Fragment />}
                modalRef={props.modalRef}
            >
                <TaskForm
                    potentialAssignees={props.potentialAssignees}
                    onSuccess={handleOnSuccess}
                    task={props.task}
                    updateMode={true}
                />
            </Modal>}
            <Button
                className={props.buttonClass || "custom-button edit-task-button"}
                onClick={openModal}
            >
                <Icon src={pencil} />
                <p>Edit Task</p>
            </Button>
        </React.Fragment>
    );
}

export default EditTask;