import React from 'react';

import lens from '../../assets/icons/lens.svg';

import Label from '../UI/Text/Label';
import TextInput from '../UI/Inputs/TextInput';

function ProjectsFilters(props) {
    const onNameEntered = (name) => {
        props.onFiltersChange({ name });
    }

    return (
        <div className="projects-filters">
            <div className="projects-filter">
                <Label htmlFor="name-filter">Search</Label>
                <TextInput
                    id="name-filter"
                    placeholder="Type the name of the inventory"
                    debouncingTime={800}
                    onChange={onNameEntered}
                    icon={lens}
                />
            </div>
        </div>
    );
}

export default ProjectsFilters;