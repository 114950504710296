import { createSlice } from '@reduxjs/toolkit';

const initialAuthState = {
    user: null,
    token: null,
};
const authSlice = createSlice({
    name: 'auth',
    initialState: initialAuthState,
    reducers: {
        saveUser(state, action) {
            state.user = action.payload;
        },
        saveToken(state, action) {
            state.token = action.payload;
        }
    }
});

export const authActions = authSlice.actions;
export default authSlice.reducer;