import React from 'react';
import { useSelector } from 'react-redux';

import lens from '../../assets/icons/lens.svg';

import Label from '../UI/Text/Label';
import TextInput from '../UI/Inputs/TextInput';
import Select from '../UI/Inputs/Select';

function UsersFilters(props) {
    const roles = useSelector((state) => state.constants.roles);

    const onKeywordEntered = (keyword) => {
        props.onFiltersChange({ keyword });
    }

    const onCompaniesChange = (company) => {
        props.onFiltersChange({ company });
    }

    const onRolesChange = (role) => {
        props.onFiltersChange({ role });
    }

    return (
        <div className="users-filters">
            <div className="users-filter">
                <Label htmlFor="keyword-filter">Keyword</Label>
                <TextInput
                    id="keyword-filter"
                    placeholder="Type a keyword"
                    debouncingTime={800}
                    onChange={onKeywordEntered}
                    icon={lens}
                />
            </div>

            <div className="users-filter">
                <Label htmlFor="company-filter">Company</Label>
                <Select
                    id="company-filter"
                    placeholder="Select a company"
                    options={props.companies}
                    keys={['name', 'id']}
                    onChange={onCompaniesChange}
                />
            </div>

            <div className="users-filter">
                <Label htmlFor="role-filter">CarbonAlly Role</Label>
                <Select
                    id="role-filter"
                    placeholder="Select a role"
                    options={roles}
                    onChange={onRolesChange}
                />
            </div>
        </div>
    );
}

export default UsersFilters;