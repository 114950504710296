import React from 'react';

import './TaskComment.scss';

import Card from '../UI/Containers/Card';

import { formatDate, dateFormats } from '../../tools/date-helper';

function TaskComment(props) {
    const comment = props.comment;

    return (
        <React.Fragment>
            <div className="comment-header">
                <p>{comment.creator_name}</p>
                <p>{formatDate(comment.created_at, dateFormats['full-en-gb'])}</p>
            </div>
            <Card className="comment-body">
                {comment.comment}
            </Card>
        </React.Fragment>
    );
}

export default TaskComment;