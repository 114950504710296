import { createSlice } from '@reduxjs/toolkit';

import { identifyCurrentTab } from '../tools/ui-helper';

const initialUIState = {
    activeTab: identifyCurrentTab(),
};
const uiSlice = createSlice({
    name: 'ui',
    initialState: initialUIState,
    reducers: {
        saveActiveTab(state, action) {
            state.activeTab = action.payload;
        },
    }
});

export const uiActions = uiSlice.actions;
export default uiSlice.reducer;