import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import ButtonLink from '../ButtonLink';

function transformKeysToColumns(keys) {
    return keys.map((key) => { return { name: key }; });
}

function renderCell(column, row) {
    if (column.key.charAt(0) === '$') return (
        <ButtonLink path={column.path.replace(':id', row.id)} icon={column.icon} />
    );
    if (column.render) return column.render(row[column.key]);
    
    return row[column.key]
}

function CarbonAllyTable(props) {
    const columns = props.columns || transformKeysToColumns(Object.keys(props.row[0]));

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                {!props.hideHeader && (props.customHeader || 
                    <TableHead>
                        <TableRow sx={{ backgroundColor: '#E0E8E9' }}>
                            {columns.map((column) => (
                                <TableCell key={`th-${column.key}`}>{column.name}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>               
                )}
                <TableBody>
                    {props.rows.map((row) => (
                        <TableRow
                            key={row.id}
                            sx={{
                                '&:last-child td, &:last-child th': { border: 0 } ,
                                '&:nth-of-type(odd)': { backgroundColor: 'white' },
                                '&:nth-of-type(even)': { backgroundColor: '#F9FBFC' },
                            }}
                        >
                            {columns.map((column) => (
                                <TableCell key={`${row.id}-${column.key}`}>{
                                    renderCell(column, row)
                                }</TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default CarbonAllyTable;