import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Button from '../../UI/Button';
import Label from '../../UI/Text/Label';
import TextInput from '../../UI/Inputs/TextInput';
import NumberInput from '../../UI/Inputs/NumberInput';
import Subsidiaries from './OrganizationalLimits/Subsidiaries';

import { projectsActions } from '../../../store/projects-slice';

function OrganizationalLimitsSection() {
    const dispatch = useDispatch();
    const project = useSelector((state) => (state.projects.currentProject));
    const organizationalLimits = project?.ghg_inventory.organizational_limits;

    const updateField = (fieldID, value) => {
        dispatch(projectsActions.updateCurrentProjectField({
            section: 'organizational_limits', fieldID, value,
        }));
    };

    const updateHoldingCompany = (value) => {
        updateField('holding_company', value);
    };

    const updateOwnershipPercentage = (value) => {
        updateField('ownership_percentage', value);
    };

    return (
        <div className="padded-section-container">
            <div className="syncghronize-erp-data-button">
                <Button>Synchronize ERP data</Button>
            </div>
            <div className="holding-company-input">
                <Label>Holding Company</Label>
                <TextInput
                    value={organizationalLimits.holding_company}
                    debouncingTime={800}
                    onChange={updateHoldingCompany}
                />
                <p className="text-under-input">If applicable</p>
            </div>
            <hr />
            <h3>Your company</h3>
            <div className="organizational-limits-inputs">
                <div className="organizational-limits-input">
                    <Label>Company Name</Label>
                    <TextInput
                        value={project.company_name}
                        disabled
                    />
                </div>
                <div className="organizational-limits-input">
                    <Label>Ownership Percentage*</Label>
                    <NumberInput
                        value={organizationalLimits.ownership_percentage}
                        category="percentage"
                        debouncingTime={800}
                        onChange={updateOwnershipPercentage}
                    />
                </div>
            </div>
            <hr />
            <Subsidiaries
                id="subsidiaries"
                subsidiaries={organizationalLimits.subsidiaries}
                onChange={updateField}
            />
        </div>
    );
}

export default OrganizationalLimitsSection;