import React, { useEffect, useRef } from 'react';

import three_dots from '../../../assets/icons/three_dots.svg';

import Icon from '../Icon';
import Card from './Card';
import Button from'../Button';

import useBoolean from '../../../hooks/use-boolean';
import { isEventFromExternalComponent } from '../../../tools/events-helper';

function collectRefs(thisRef, items) {
    return items.map((item) => (item.ref)).filter(Boolean).concat(thisRef);
}

function QuickMenu(props) {
    const thisRef = useRef();
    const { boolean: showMenu, toggle: toggleMenu, setFalse: hideMenu } = useBoolean();
    const refs = collectRefs(thisRef, props.items);

    const handleClickOutside = (event) => {
        if (isEventFromExternalComponent(event)) return;

        for (const ref of refs)
            if (ref.current?.contains(event.target))
                return;

        hideMenu();
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div ref={thisRef} className="quick-menu-container">
            <Icon src={props.icon || three_dots} onClick={toggleMenu} />
            {showMenu && (
                <Card className={`quick-menu ${props.direction || 'right'}`}>
                    {props.items.map((item, index) => {
                        const onClickWrapper = () => {
                            hideMenu();
                            if (item.onClick) item.onClick();
                        };

                        if (item.customItem)
                            return React.createElement(
                                item.customItem,
                                { ...item.props, onClick: onClickWrapper, onDismiss: hideMenu, key: index }
                            );

                        return (
                            <Button
                                className="quick-menu-item"
                                disabled={item.disabled || false}
                                onClick={onClickWrapper}
                                key={index}
                            >
                                {item.text}
                            </Button>
                        );
                    })}
                </Card>
            )}
        </div>
    );
}

export default QuickMenu;