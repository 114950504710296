import React, { useState, useRef } from 'react';

import Button from '../UI/Button';
import Modal from '../UI/Containers/Modal';
import Label from '../UI/Text/Label';
import TextArea from '../UI/Inputs/TextArea';

import useBoolean from '../../hooks/use-boolean';
import useAxios from '../../hooks/use-axios';

function AddComment(props) {
    const { boolean: isModalOpen, setTrue: openModal, setFalse: closeModal } = useBoolean();
    const { sendRequest: createTaskComment } = useAxios();
    const commentRef = useRef();
    const [error, setError] = useState(null);

    const handleOnSubmit = (event) => {
        event.preventDefault();
        const createTaskCommentRequest = {
            url: '/task_comments',
            method: 'post',
            params: {
                task_comment: {
                    comment: commentRef.current.value,
                    task_id: props.taskID,
                }
            },
            manageResponse: true,
        };
        createTaskComment(createTaskCommentRequest, (data) => {
            if (data.success === 'true') {
                closeModal();
                props.onSuccess();
            } else
                setError(data.reason); // TODO: show details of why the task could not be created
        });
    }

    return (
        <React.Fragment>
            {isModalOpen && 
            <Modal
                title={`${props.taskTitle} - New comment`}
                onDismiss={closeModal}
                footer={<React.Fragment />}
                modalRef={props.modalRef}
            >
                <form onSubmit={handleOnSubmit} className="task-comment-form">
                    <div className="add-comment-input">
                        <Label htmlFor="comment-input">Comment*</Label>
                        <TextArea
                            id="comment-input"
                            name="comment"
                            placeholder="What would you like to comment?"
                            inputRef={commentRef}
                            maxLength="900"
                        />
                    </div>

                    {error && <p className="task-comment-form-error">{error}</p>}

                    <div className="task-form-submit">
                        <p>*This field is mandatory</p>
                        <Button type='submit'>Create comment</Button>
                    </div>
                </form>
            </Modal>}
            <Button onClick={openModal}>
                Add comment
            </Button>
        </React.Fragment>  
    );
}

export default AddComment;