import React from 'react';
import { useSelector } from 'react-redux';

import Button from '../../../UI/Button';
import GHGInventoryComponent from '../../../GHGInventory/GHGInventoryComponent';

import useBoolean from '../../../../hooks/use-boolean';

function ScopeSectionComponent(props) {
    const component = props.component;
    const { boolean: isModalOpen, setTrue: openModal, setFalse: closeModal } = useBoolean();
    const componentTypes = useSelector((state) => (state.GHGInventory.componentTypes));
    
    switch (props.type) {
        case componentTypes.button:
            return (
                <Button onClick={component?.onClick || props.onClick}>{component?.text || props.text}</Button>
            );
        case componentTypes.modal:
            return (
                <React.Fragment>
                    {isModalOpen && (
                        <GHGInventoryComponent
                            id={component.id}
                            onDismiss={closeModal}
                        />
                    )}
                    <ScopeSectionComponent
                        onClick={openModal}
                        text={component.text}
                        type={component.trigger_component}    
                    />
                </React.Fragment>
            );
        case componentTypes.custom_content:
            return (
                <GHGInventoryComponent id={component.id} />
            )
        default:
            return (
                <p>Error while loading ScopeSectionComponent: unknown type "{props.type}"</p>
            );
    }
}

export default ScopeSectionComponent;