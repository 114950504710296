import React from 'react';

import Card from '../Containers/Card';

const defaultEmptyMessage = 'Sorry, there are no items to display';

function List(props) {
    const classes = 'list-item ' + (props.className || ''); // TODO: check if every reusable component should use this pattern
    const defaultProps = {
        onClick: props.onClick
    };
    let index = 0;

    const emptyList = props.customEmptyList || (
        <h2 className="empty-list">
            {props.emptyMessage || defaultEmptyMessage}
        </h2>
    );
    
    return (
        <React.Fragment>
            {props.items.length <= 0 && emptyList}
            {props.items.map((item) => {
                const listItemProps = props.model
                    ? { [props.model]: item, ...defaultProps }
                    : { ...item, ...defaultProps };

                return (
                    <Card className={classes} key={index}>
                        {React.createElement(
                            props.itemElement,
                            { ...listItemProps, index: index++ }
                        )}
                    </Card>
                );
            })}
        </React.Fragment>
    );
}

export default List;