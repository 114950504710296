import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import './CarbonCreditBatches.scss';

import Select from '../UI/Inputs/Select';
import Tabs from '../UI/Tabs/Tabs';
import OffsettingProjects from './OffsettingProjects';

import useAxios from '../../hooks/use-axios';

import { offsettingProjectsActions } from '../../store/offsetting-projects-slice';

function CarbonCreditBatches() {
    const dispatch = useDispatch();
    const { sendRequest: fetchCarbonCreditBatches } = useAxios();

    useEffect(() => {
        const fetchCarbonCreditBatchesRequest = {
            url: '/carbon_credit_batches'
        };
        fetchCarbonCreditBatches(fetchCarbonCreditBatchesRequest, (data) => {
            dispatch(offsettingProjectsActions.setOffsettingProjects(data.carbon_credit_batches));
            dispatch(offsettingProjectsActions.setCompanyOffsettingProjects(data.company_carbon_credit_batches));
        });
    }, [fetchCarbonCreditBatches]);

    const tabs = [
        { name: 'All Projects', content: <OffsettingProjects companyProject={false} /> },
        { name: 'Your Projects', content: <OffsettingProjects companyProject={true} /> },
    ];

    return (
        <React.Fragment>
            <div className="offsetting-projects-filters">
                <Select
                    placeholder="Type of the project"
                    options={[]}
                />
                <Select
                    placeholder="Localization"
                    options={[]}
                />
            </div>
            <div className="offsetting-projects-container">
                <Tabs
                    tabs={tabs}
                    contentContainerClasses="offsetting-project-tabs"
                />
            </div>
        </React.Fragment>
    );
}

export default CarbonCreditBatches;