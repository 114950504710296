import React from 'react';

import sampleOrganigram from '../../../assets/sample-organigram.png';

function ChartsSection(props) {
    return (
        <React.Fragment>
            Under development (sample image)
            <img src={sampleOrganigram} className="sample-charts-image"/>
        </React.Fragment>
    );
}

export default ChartsSection;