import React, { useRef, useState } from 'react';

import './UserForm.scss';

import TextInput from '../UI/Inputs/TextInput';
import Label from '../UI/Text/Label';
import Button from '../UI/Button';

import useAxios from '../../hooks/use-axios';

function UserForm(props) {
    const [userUpdated, setUserUpdated] = useState(false);
    const [error, setError] = useState(null);
    const nameRef = useRef();
    const lastNameRef = useRef();
    const { error: httpError, sendRequest: updateUser } = useAxios();

    const handleObSubmit = async (event) => {
        event.preventDefault();
        const updateUserRequest = {
            url: `/users/${props.user.id}`,
            method: 'post',
            params: {
                user: {
                    name: nameRef.current.value,
                    last_name: lastNameRef.current.value,
                }
            },
            manageResponse: true
        };
        updateUser(updateUserRequest, (data) => {
            if (data.success === 'true')
                setUserUpdated(true);
            else
                setError(data.details ? `${data.reason}:\n${data.details}` : data.reason);
        });
    }

    return (
        <form className="user-form" onSubmit={handleObSubmit}>
            <div className="user-form-row">
                <div className="user-form-input">
                    <Label htmlFor="name-input">Name</Label>
                    <TextInput
                        id="name-input"
                        inputRef={nameRef}
                        value={props.user.name}
                    />
                </div>
                <div className="user-form-input">
                    <Label htmlFor="company-input">Company</Label>
                    <TextInput
                        id="company-input"
                        value={props.user.company_name}
                        disabled
                    />
                </div>
            </div>
            <div className="user-form-row">
                <div className="user-form-input">
                    <Label htmlFor="last-name-input">Last name</Label>
                    <TextInput
                        id="last-name-input"
                        inputRef={lastNameRef}
                        value={props.user.last_name}
                    />
                </div>
                <div className="user-form-input">
                    <Label htmlFor="email-input">Email</Label>
                    <TextInput
                        id="email-input"
                        value={props.user.email}
                        disabled
                    />
                </div>
            </div>
            <div className="user-form-row">
                <Button type="submit">Save</Button>
            </div>
            {userUpdated && <p className="user-updated">User has been updated successfully</p>}
            {httpError || error && <p className="user-update-error">{httpError || error}</p>}
        </form>
    );
}

export default UserForm;