import React, { useState, useRef, useEffect } from 'react';

import Label from '../Text/Label';
import Checkbox from './Checkbox';
import Tooltip from '../Text/Tooltip';
import TextInput from './TextInput';

import { validateUpcasedFirstCharacter } from '../../../tools/input-validators';

function CheckboxGroup(props) {
    const [selectedOptions, setSelectedOptions] = useState(props.value || []);
    const otherRef = useRef();

    if (props.debouncingTime && props.onChange) {
        useEffect(() => {
            const id = setTimeout(() => {
                props.onChange(selectedOptions);
            }, props.debouncingTime);
            return () => {
                clearTimeout(id);
            };
        }, [selectedOptions]);
    }

    const toggleOption = (option) => {
        setSelectedOptions((oldSelectedOptions) => (
            oldSelectedOptions.includes(option)
            ? oldSelectedOptions.filter((selectedOption) => (selectedOption != option))
            : oldSelectedOptions.concat([option])
        ));
    };

    // TODO: Should we remove Other from CheckboxGroup?
    // TODO: move tooltip logic into Checkbox.jsx
    return (
        <React.Fragment>
            {props.options.map((option) => {
                const value = option.value ? option.value : option;

                return (
                    <Checkbox
                        key={value}
                        onToggleOption={toggleOption}
                        value={value}
                        className={props.className}
                        checked={selectedOptions.indexOf(value) > -1}
                    >
                        {value}
                        { option.tooltip && (
                            <Tooltip
                                content={option.tooltip}
                                icon={option.tooltip_icon}
                                direction={option.tooltip_direction}
                            />
                        )}
                    </Checkbox>
                );
            })}
            {props.otherAvailable && (
                <React.Fragment>
                    <Label>Other:</Label>
                    <TextInput
                        inputRef={otherRef}
                        className={props.otherClassName}
                        validationFunction={validateUpcasedFirstCharacter}
                    />
                </React.Fragment>
            )}
        </React.Fragment>
    );
}

export default CheckboxGroup;