import React from "react";
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import './AppHeader.scss';
import notifications_pending from '../../assets/icons/notifications_pending.svg';
import user_dark from '../../assets/icons/user_dark.svg';

import Icon from '../UI/Icon';

function AppHeader(props) {
    const user = useSelector((state) => state.auth.user);

    return (
        <div className="app-header">
            <Icon src={notifications_pending} />
            <Icon src={user_dark} onClick={props.onLogout} />
            <Link to="/profile">
                <div className="user-menu">
                    <p className="user-name">{user?.full_name}</p>
                    <p className="user-role">{user?.role}</p>
                </div>
            </Link>
        </div>
    );
}

export default AppHeader;