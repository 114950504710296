import React from 'react';

import './GHGInventoryComponent.scss';

import IntroductionModal from './Scope1/FugitiveEmissions/IntroductionModal';
import GlobalWarmingPotentialModal from './Scope1/FugitiveEmissions/GlobalWarmingPotentialModal';
import DefaultIPCCValuesModal from './Scope1/FugitiveEmissions/DefaultIPCCValuesModal';
import IntroductionText from './Scope3/IntroductionText';

function GHGInventoryComponent(props) {
    switch (props.id) {
        case '1-3-1':
            return <IntroductionModal onDismiss={props.onDismiss} />
        case '1-3-2':
            return <GlobalWarmingPotentialModal onDismiss={props.onDismiss} />
        case '1-3-3':
            return <DefaultIPCCValuesModal onDismiss={props.onDismiss} />
        case '3-1':
            return <IntroductionText />
        default:
            return <p>Error while loading GHGInventoryComponent: unknown id "{props.id}"</p>
    }
}

export default GHGInventoryComponent;