import qs from 'qs';

const encodings = [
    { UTF8: '%5B', character: '[' },
    { UTF8: '%5D', character: ']' },
];

function translateEncoding(string, encoding) {
    let translatedString = string;
    
    for (const translator of encodings)
        translatedString = translatedString.replaceAll(translator[encoding], translator.character);

    return translatedString;
}

export function serializeAsBrackets(params) {
    return translateEncoding(qs.stringify(params, { arrayFormat: 'brackets' }), 'UTF8');
}
