import React from 'react';

function Icon(props) {
    return (
        <img
            {...props}
            id={props.id}
            className={props.className}
            src={props.src}
            onClick={props.onClick}
            width={props.width}
            height={props.height}
            ref={props.imgRef}
        />
    );
}

export default Icon;