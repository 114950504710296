import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import Label from '../UI/Text/Label';
import TextInput from '../UI/Inputs/TextInput';
import TextArea from '../UI/Inputs/TextArea';
import Select from '../UI/Inputs/Select';
import FileInput from '../UI/Inputs/FileInput';
import Button from '../UI/Button';

import useAxios from '../../hooks/use-axios';

function OffsettingProjectForm(props) {
    const statuses = useSelector((state) => state.constants.offsettingProjectStatuses);
    const [error, setError] = useState(null);
    const projectNameRef = useRef();
    const amountRef = useRef();
    const priceRef = useRef();
    const standardRef = useRef();
    const statusRef = useRef();
    const descriptionRef = useRef();
    const imageRef = useRef([]);
    const { sendRequest: createOrUpdateProject } = useAxios();

    const handleOnSubmit = (event) => {
        event.preventDefault();
        const carbon_credit_batch = {
            project_name: projectNameRef.current.value,
            amount: amountRef.current.value,
            price: priceRef.current.value,
            description: descriptionRef.current.value,
            standard: standardRef.current.value,
            status: statusRef.current.value,
        };

        let image = null; 
        if (imageRef.current.length > 0) { // TODO: look for a better way of sending the image (if there is)
            image = new FormData();
            image.append('image', imageRef.current[0]);
        }

        const createOrUpdateProjectRequest = {
            url: `/carbon_credit_batches/${props.updateMode ? props.project.id : ''}`,
            method: 'post',
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data: image,
            params: {
                carbon_credit_batch
            },
            manageResponse: true,
        };
        createOrUpdateProject(createOrUpdateProjectRequest, (data) => {
            if (data.success === 'true')
                props.onSuccess(data.carbon_credit_batch);
            else
                setError(data.reason); // TODO: show details of why the project could not be created/updated
        });
    }

    return (
        <form onSubmit={handleOnSubmit} className="custom-form" style={{ padding: '0px' }}>
            <div className="custom-form-inputs">
                <div className="custom-form-input">
                    <Label htmlFor="project-name-input">Project name</Label>
                    <TextInput
                        id="project-name-input"
                        value={props.project?.project_name || ''}
                        placeholder="Project name"
                        inputRef={projectNameRef}
                    />
                </div>
            </div>
            <div className="custom-form-inputs">
                <div className="custom-form-input">
                    <Label htmlFor="amount-input">Credit quantity</Label>
                    <TextInput
                        id="amount-input"
                        value={props.project?.amount || ''}
                        placeholder="100"
                        inputRef={amountRef}
                    />
                </div>
                <div className="custom-form-input">
                    <Label htmlFor="price-input">Price</Label>
                    <TextInput
                        id="price-input"
                        value={props.project?.price || ''}
                        placeholder="$200"
                        inputRef={priceRef}
                    />
                </div>
            </div>
            <div className="custom-form-inputs">
                <div className="custom-form-input">
                    <Label htmlFor="standard-input">Standard</Label>
                    <TextInput
                        id="standard-input"
                        value={props.project?.standard || ''}
                        inputRef={standardRef}
                    />
                </div>
                <div className="custom-form-input">
                    <Label htmlFor="status-input">Status</Label>
                    <Select
                        id="status-input"
                        value={props.project?.status || ''}
                        placeholder="Select a status"
                        options={statuses || []}
                        selectRef={statusRef}
                    />
                </div>
            </div>
            <div className="custom-form-inputs">
                <div className="custom-form-description-input">
                    <Label htmlFor="name-input">Brief description</Label>
                    <TextArea
                        id="name-input"
                        name="description"
                        value={props.project?.description || ''}
                        placeholder="Type the project description..."
                        inputRef={descriptionRef}
                        maxLength="900"
                    />
                </div>
            </div>
            <div style={{ marginLeft: '30px', marginRight: '30px' }}>
                <FileInput
                    placeholder='Upload a photo for the project'
                    id={props.updateMode ? `upload-photo-${props.project.id}` : 'upload-photo'}
                    ref={imageRef}
                />
            </div>

            {error && <p className="custom-form-error">{error}</p>}

            <div className="custom-form-submit">
                <Button type='submit'>{props.updateMode ? 'Update project' : 'Create new project'}</Button>
            </div>
        </form>
    );
}

export default OffsettingProjectForm;