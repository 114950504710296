import React, { useState } from 'react';

import checkedCheckbox from '../../../assets/icons/checkedCheckbox';
import uncheckedCheckbox from '../../../assets/icons/uncheckedCheckbox';

import Icon from '../Icon';

function Checkbox(props) {
    const classes = 'custom-checkbox ' + props.className;
    const [checked, setChecked] = useState(props.checked || false);
    const checkboxIcon = checked ? checkedCheckbox : uncheckedCheckbox;
    const cursor = props.disabled ? 'default' : 'pointer';

    const toggleChecked = () => {
        if (!props.disabled)
            setChecked((oldChecked) => (!oldChecked));
        if (props.onToggleOption)
            props.onToggleOption(props.value);
    };

    return (
        <span className={classes} onClick={toggleChecked} style={{ cursor }}>
            <Icon src={checkboxIcon} /> {props.children}
        </span>
    );
}

export default Checkbox;