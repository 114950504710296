import React from 'react';
import { useSelector } from 'react-redux';

import './Question.scss';

import CheckboxGroup from '../../../UI/Inputs/CheckboxGroup';
import RadioButtonGroup from '../../../UI/Inputs/RadioButtonGroup';
import TextInput from '../../../UI/Inputs/TextInput';
import NumberInput from '../../../UI/Inputs/NumberInput';
import FileInput from '../../../UI/Inputs/FileInput';

import { validateUpcasedFirstCharacter } from '../../../../tools/input-validators';

function Question(props) {
    const questionTypes = useSelector((state) => (state.GHGInventory.questionTypes));
    const question = props.question;
    const questionID = `question-${props.scope}-${props.sectionID}-${question.id}`;
    const questionClass = question.layout ? `${question.layout}-question` : 'vertical-question';

    const handleOnChange = (value) => {
        props.onUpdate(props.sectionID, question.id, value, props.index);
    };

    const renderQuestionType = () => {
        switch (question.type) {
            case questionTypes.checkbox_group:
                return (
                    <CheckboxGroup
                        value={props.value}
                        options={question.options}
                        otherAvailable={question.other_available}
                        id={questionID}
                        className="question-checkbox"
                        otherClassName="question-input"
                        debouncingTime={800}
                        onChange={handleOnChange}
                    />
                );
            case questionTypes.radio_button_group:
                return (
                    <RadioButtonGroup
                        value={props.value}
                        options={question.options}
                        otherAvailable={question.other_available}
                        id={questionID}
                        className="question-checkbox"
                        otherClassName="question-input"
                        debouncingTime={800}
                        onChange={handleOnChange}
                    />
                );
            case questionTypes.text:
                return (
                    <TextInput
                        value={props.value}
                        id={questionID}
                        className="question-input"
                        validationFunction={validateUpcasedFirstCharacter}
                        debouncingTime={800}
                        onChange={handleOnChange}
                    />
                );
            case questionTypes.integer:
                return (
                    <NumberInput
                        value={props.value}
                        id={questionID}
                        category="integer"
                        className="question-input"
                        debouncingTime={800}
                        onChange={handleOnChange}
                    />
                );
            case questionTypes.real:
                return (
                    <NumberInput
                        value={props.value}
                        id={questionID}
                        category="real"
                        className="question-input"
                        debouncingTime={800}
                        onChange={handleOnChange}
                    />
                );
            default:
                return (
                    <p>Error while loading question: unknown type "{question.type}"</p>
                );
        }
    };

    return (
        <div className={questionClass}>
            <p className="question-name">{question.question}</p>
            <div>
                {renderQuestionType()}
            </div>
            {question.request_documentation && (
                <FileInput
                    id={`file-input-${questionID}`}
                    placeholder="Please upload the applicable documentation"
                    multiple={true}
                />
            )}
        </div>
    );
}

export default Question;