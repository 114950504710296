import React from 'react';
import { Link } from 'react-router-dom';

import Icon from './Icon';

function ButtonLink(props) {
    const className = 'button-link ' + props.className; 
    return (
        <Link className={className} to={props.path}>
            <Icon src={props.icon} />
        </Link>
    );
}

export default ButtonLink;