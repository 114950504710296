import React from 'react';

import Modal from '../UI/Containers/Modal';
import Button from '../UI/Button';
import NewCompanyForm from './NewCompanyForm';

import useBoolean from '../../hooks/use-boolean';
import useNotification from '../../hooks/use-notification';

function AddCompany() {
    const { pushNotification } = useNotification();
    const { boolean: isModalOpen, setTrue: openModal, setFalse: closeModal } = useBoolean();

    const handleOnSuccess = () => {
        pushNotification({ message: 'The company was added successfully' });
        closeModal();
    };

    return (
        <div className='add-company'>
            {isModalOpen && 
            <Modal
                title="Add new company"
                onDismiss={closeModal}
                footer={<React.Fragment />}
            >
                <NewCompanyForm
                    onSuccess={handleOnSuccess}
                />
            </Modal>}
            <Button onClick={openModal}>Add new company</Button>
        </div>
    );
}

export default AddCompany;