import { createSlice } from '@reduxjs/toolkit';

const initialOffsettingProjectsState = {
    offsettingProjects: [],
    companyOffsettingProjects: [],
    newestOffsettingProject: {},
};
const offsettingProjectsSlice = createSlice({
    name: 'OffsettingProjects',
    initialState: initialOffsettingProjectsState,
    reducers: {
        setOffsettingProjects(state, action) {
            state.offsettingProjects = action.payload;
        },
        setCompanyOffsettingProjects(state, action) {
            state.companyOffsettingProjects = action.payload;
        },
        addCompanyOffsettingProject(state, action) {
            state.companyOffsettingProjects = [...state.companyOffsettingProjects, action.payload];
            state.newestOffsettingProject = action.payload;
        },
        updateCompanyOffsettingProject(state, action) {
            state.companyOffsettingProjects = state.companyOffsettingProjects.map((project) => {
                if (project.id === action.payload.id)
                    return action.payload;
                return project;
            });
        },
        removeCompanyOffsettingProject(state, action) {
            state.companyOffsettingProjects = state.companyOffsettingProjects.filter((project) => (
                project.id !== action.payload
            ));
        },
    }
});

export const offsettingProjectsActions = offsettingProjectsSlice.actions;
export default offsettingProjectsSlice.reducer;