import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import './ProjectItem.scss';
import eye from '../../assets/icons/eye.svg';
import pencil from '../../assets/icons/pencil.svg';

import Icon from '../UI/Icon';

import useBoolean from '../../hooks/use-boolean';
import arrow_up from '../../assets/icons/arrow_up.svg';
import arrow_down from '../../assets/icons/arrow_down.svg';
import Card from '../UI/Containers/Card';
import Table from '../UI/Data/Table';
import Pagination from '@mui/material/Pagination';

import Checkbox from '../UI/Inputs/Checkbox';
import { List } from '@mui/material';

//TODO: Add Pagination and provide UI for assigning employees

function ProjectItem(props) {
    const project = props.project;
    const { boolean: projectDropdown, toggle: toggleDropdown} = useBoolean();

    const columns = [
        { key: 'name', name: 'Name' },
        { key: 'last_name', name: 'LastName' },
        { key: 'title', name: 'Title' },
        { key: 'email', name: 'Email' },
    ];

    return (
        <React.Fragment>
            <div className="project-header">
                <div className="project-title">
                    <p className="project-name">{project.name}</p>
                    {project.scopes && (
                        <p className="project-scopes"> - {project.scopes.join(', ')}</p>
                    )}
                </div>
                <div className="project-actions">
                    <Icon src={pencil} />
                    <Link to={`/inventories/${project.id}`}>
                        <Icon src={eye} />
                    </Link>
                </div>
            </div>
            <div className="project-owner">
                <label>RESPONSIBLE PERSON</label>
                <p>{project.owner_name}</p>
            </div>
            <hr />
            <div >
                <Card >
                    <div className="stakeholders-header">
                        <label className="stakeholders-header-label">Stakeholders</label>
                        <Icon 
                            className="stakeholders-header-icon"
                            src={projectDropdown ? arrow_up : arrow_down}
                            onClick={toggleDropdown}
                        />
                    </div>
                    {
                        projectDropdown && 
                        <div>
                            <Table rows={project.employees} columns={columns} hideHeader={true}/>
                            {/* <Pagination
                                count={pages}
                                page={props.page}
                                onChange={props.onPageChange}
                                shape="rounded"
                            /> */}
                        </div>
                    }
                </Card>
            </div>
        </React.Fragment>
    );
}

export default ProjectItem;