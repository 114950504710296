import React from 'react';

function IntroductionText() {
    return (
        <div className="light-gray-ghg-body">
            <p className="standard-ghg-text">
                These emissions are the indirect emissions that occur in the organization's value chain. There is no requirement to be fully reported by companies, since the data is often beyond the control of the company, it is understood that in the limit, if everyone makes inventories of scope 1 and 2 emissions of scope 3 would then be double counting. I suggest in the first moment to make the tool only for scope 1 and 2 and at most include emissions from air travel and commuting.
            </p>
            <ul>
                <li className="bullet-list-item">
                    Transport and Distribution Emissions (upstream): emissions from transport and distribution of products in vehicles and facilities that are not owned or operated by the organization, when these services are purchased or acquired by the inventorying organization in the inventory year, as well as from other outsourced transport and distribution services (including both inbound and outbound logistics).
                </li>
            </ul>
            <ul>
                <li className="bullet-list-item">
                    Emissions from waste and effluents treated off-site: includes emissions from the treatment and/or final disposal of solid waste and effluents arising from the organization's operations performed in facilities owned or controlled by third parties.
                </li>
            </ul>
            <ul>
                <li className="bullet-list-item">
                    Emissions from business travel: emissions from employee transport for activities related to the inventor organization's business, carried out in vehicles operated by or owned by third parties, in aircraft, trains, buses, passenger cars and boats. All employees of entities and units operated, leased, or owned by the inventor organization are considered in this category. Employees of other relevant entities (e.g., third-party service providers) as well as consultants and other individuals who are not employees of the inventor organization but travel to its units may be included in this category.
                </li>
            </ul>
            <ul>
                <li className="bullet-list-item">
                    Emissions from commuting between home and work: emissions from the transportation of employees in their commute between home and work, carried out in employees' private vehicles or public transportation (train, urban subway, municipal and travel buses). All employees of entities and units operated, rented or owned by the inventor organization are considered in this category. Employees of other relevant entities (e.g., third-party service providers) as well as consultants and other individuals who are not employees of the inventor organization but travel to its units may be included in this category.
                </li>
            </ul>
            <ul>
                <li className="bullet-list-item">
                    Downstream Emissions: emissions from transport and distribution of products in vehicles and facilities that are not owned or operated by the organization, when there is no purchase or acquisition relationship of these services by the inventorying organization in the inventory year, as well as other outsourced transport and distribution services (including both inbound and outbound logistics).
                </li>
            </ul>
        </div>
    )
}

export default IntroductionText;