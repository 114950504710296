import React from 'react';

import Modal from '../UI/Containers/Modal';
import Button from '../UI/Button';

function DeleteModal(props) {
    const footer = (
        <div className="delete-modal-footer">
            <Button
                className="custom-button-white"
                onClick={props.onDismiss}
            >
                No
            </Button>
            <Button onClick={props.onSuccess}>Yes</Button>
        </div>
    );

    return (
        <Modal
            title="Do you really want to delete this project?"
            width={40}
            onDismiss={props.onDismiss}
            footer={footer}
        >
            <p>This is a definite action.</p>
        </Modal>
    );
}

export default DeleteModal;