import React from 'react';
import { Link } from 'react-router-dom';

import './TaskItem.scss';
import eye from '../../assets/icons/eye.svg';

import Icon from '../UI/Icon';

import { formatDate } from '../../tools/date-helper';

function TaskItem(props) {
    const task = props.task;

    return (
        <React.Fragment>
            <div className="task-item-title">
                {task.title}
                <Link to={`/tasks/${task.category_name}/${task.id}`}>
                    <Icon src={eye} />
                </Link>
            </div>
            <p className="task-item-field">
                DEADLINE<br />{formatDate(task.delivery_date) || 'N/A'}
            </p>
            <p className="task-item-field">
                CREATED BY<br />{task.creator.full_name}
            </p>
            <p className="task-item-field">
                ASSIGNED TO<br />{task.assignee?.full_name || 'N/A'}
            </p>
        </React.Fragment>
    );
}

export default TaskItem;