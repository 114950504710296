import React from 'react';

// "options" prop should be an array of objects.
// By default, each option object property :name is shown as option and property :value stored as value.
// This default behavior can be overrided by passing a pair of keys to read from.
// E.g.: props.keys -> ['colorName', 'colorRGB'] 
function Select(props) {
    const classes = 'custom-select ' + props.className;
    const [key, value] = props.keys || ['name', 'value'];

    const defaultValue = props.multiple ? [] : '';

    const handleOnChange = (event) => {
        if (props.onChange)
            props.onChange(event.target.value);
    };

    return (
        <select
            className={classes}
            id={props.id || ''}
            multiple={props.multiple || false}
            defaultValue={props.value || defaultValue}
            onChange={handleOnChange}
            ref={props.selectRef}
        >
            <option value={defaultValue} disabled>{props.placeholder || ''}</option>
            {props.options.map((option) => (
                <option
                    key={option[key]}
                    value={option[value]}
                >
                    {option[key]}
                </option>
            ))}
        </select>
    );
}

export default Select;