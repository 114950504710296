import React from "react";

import Loading from '../components/Layout/Loading';
import Profile from '../components/Profile/Profile';
import Products from '../components/Products/Products';
import Users from '../components/Users/Users';
import Companies from '../components/Companies/Companies';
import Projects from '../components/Projects/Projects';
import Project from '../components/Projects/Project';
import Tasks from '../components/Tasks/Tasks';
import TaskCategory from '../components/Tasks/TaskCategory';
import Task from '../components/Tasks/Task';
import CarbonCreditBatches from '../components/CarbonCreditBatches/CarbonCreditBatches';
import TaxCredits from "../components/TaxCredits/TaxCredits";
import Dashboard from "../components/Dashboard/Dashboard";

// TODO: should this be moved to a constants.js file?
export const routeNames = {
    companies: 'companies',
    users: 'users',
    products: 'products',
    inventories: 'inventories',
    inventoryShow: 'inventoryShow',
    tasks: 'tasks',
    taskCategory: 'taskCategory',
    taskShow: 'taskShow',
    offsettingProjects: 'offsettingProjects',
    taxCredits: 'taxCredits',
    dashboard: 'dashboard'
}

export const routes = [
    { path: '/users', element: <Users />, name: routeNames.users },
    { path: '/companies', element: <Companies />, name: routeNames.companies },
    { path: '/products', element: <Products />, name: routeNames.products },
    { path: '/inventories', element: <Projects />, name: routeNames.inventories },
    { path: '/inventories/:id', element: <Project />, name: routeNames.inventoryShow },
    { path: '/tasks', element: <Tasks />, name: routeNames.tasks },
    { path: '/tasks/:name', element: <TaskCategory />, name: routeNames.taskCategory },
    { path: '/tasks/:name/:id', element: <Task />, name: routeNames.taskShow },
    { path: '/offsetting_projects', element: <CarbonCreditBatches />, name: routeNames.offsettingProjects },
    { path: '/ira_tax_credits', element: <TaxCredits />, name: routeNames.taxCredits },
    { path: '/dashboard', element: <Dashboard />, name: routeNames.dashboard }
];

export const sharedRoutes = [
    { path: '/profile', element: <Profile /> },
    { path: '/loading', element: <Loading /> },
]