import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import TextInput from '../../UI/Inputs/TextInput';
import Label from '../../UI/Text/Label';
import RadioButtonGroup from '../../UI/Inputs/RadioButtonGroup';
import Select from '../../UI/Inputs/Select';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { parseDate, dateFormats } from '../../../tools/date-helper';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import TextArea from '../../UI/Inputs/TextArea';

import { projectsActions } from '../../../store/projects-slice';

function InventoryDataSection(props) {
    const dispatch = useDispatch();
    const project = useSelector((state) => (state.projects.currentProject));
    const inventoryData = project?.ghg_inventory.inventory_data;

    const updateField = (fieldID, value) => {
        dispatch(projectsActions.updateCurrentProjectField({
            section: 'inventory_data', fieldID, value,
        }));
    };

    const updateInventoryLabel= (value) => {
        updateField('inventory_label', value);
    };

    const updateInventoryYearOption= (value) => {
        updateField('inventory_year_option', value);
    };

    const updateInventoryYear= (value) => {
        updateField('inventory_year', value);
    };

    return (
        <React.Fragment>
            <div className="inventory-label">
                <Label>Inventory Label</Label>
                <TextInput
                    value={inventoryData.inventory_label}
                    onChange={updateInventoryLabel}
                    debouncingTime={800}
                />
            </div>
            <div className="inventory-year-radio-button">
                <RadioButtonGroup
                    value={'Fiscal Year'/*inventoryData.inventory_label*/}
                    options={["Fiscal Year", "Calendar Year"]}
                    onChange={updateInventoryYearOption}
                    debouncingTime={200}
                />
            </div>
            <div className="inventory-year-option">
                <div className="inventory-year-select">
                    {inventoryData.inventory_year_option=="Calendar Year" &&
                        <div className="inventory-year-input">
                            <Label>Year</Label>
                            <Select
                                id="inventory-select"
                                placeholder="Select a year"
                                options={[]}
                                onChange={updateInventoryYear}
                            />
                        </div>
                    }
                </div>
                <div className="inventory-year-date">
                    {inventoryData.inventory_year_option=="Fiscal Year" &&
                        <LocalizationProvider dateAdapter={AdapterLuxon}>
                            <div className="inventory-year-input">
                                <Label>Start date</Label>
                                <DatePicker
                                    id="start-date-input"
                                    format={dateFormats['en_gb']}
                                />
                            </div>
                            <div className="inventory-year-input">
                                <Label>End date</Label>
                                <DatePicker
                                    id="end-date-input"
                                    format={dateFormats['en_gb']}
                                />
                            </div>
                        </LocalizationProvider>
                    }
                </div>
            </div>  
            <div className="inventory-exclusions">
                    <Label >Exclusions</Label>
                    <TextArea
                        id="exclusions-input"
                        name="exclusions"
                        placeholder="Type the exclusions..."
                        maxLength="900"
                    />
                </div>
        </React.Fragment>
    );
}

export default InventoryDataSection;