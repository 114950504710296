import React, { useRef, useState } from 'react';

import Label from '../UI/Text/Label';
import TextInput from '../UI/Inputs/TextInput';
import TextArea from '../UI/Inputs/TextArea';
import Select from '../UI/Inputs/Select';
import Button from '../UI/Button';

import useAxios from '../../hooks/use-axios';

function NewProjectForm(props) {
    const [error, setError] = useState(null);
    const nameRef = useRef();
    const ownerIDRef = useRef();
    const descriptionRef = useRef();
    const { sendRequest: createProject } = useAxios();

    const handleOnSubmit = (event) => {
        event.preventDefault();
        const createProjectRequest = {
            url: '/projects',
            method: 'post',
            params:{
                project: {
                    name: nameRef.current.value,
                    description: descriptionRef.current.value,
                    owner_id: ownerIDRef.current.value,
                }
            },
            manageResponse: true,
        };
        createProject(createProjectRequest, (data) => {
            if (data.success === 'true')
                props.onSuccess(data.project_id);
            else
                setError(data.reason); // TODO: show details of why the project could not be created
        });
    }

    return (
        <form onSubmit={handleOnSubmit} className="add-project-form">
            <div className="add-project-inputs">
                <div className="add-project-input">
                    <Label htmlFor="name-input">Name*</Label>
                    <TextInput
                        id="name-input"
                        placeholder="Type the project name"
                        inputRef={nameRef}
                    />
                </div>
                <div className="add-project-input">
                    <Label htmlFor="owner-input">Project Owner*</Label>
                    <Select
                        id="owner-input"
                        placeholder="Select a project owner"
                        options={props.potentialOwners}
                        keys={['full_name', 'id']}
                        selectRef={ownerIDRef}
                    />
                </div>
            </div>
            <div className="add-project-inputs">
                <div className="add-project-description-input">
                    <Label htmlFor="name-input">Description</Label>
                    <TextArea
                        id="name-input"
                        name="description"
                        placeholder="Type the project description..."
                        inputRef={descriptionRef}
                        maxLength="900"
                    />
                </div>
            </div>

            {error && <p className="add-project-error">{error}</p>}

            <div className="add-project-submit">
                <p>*This field is mandatory</p>
                <Button type='submit'>Create new project</Button>
            </div>
        </form>
    );
}

export default NewProjectForm;