import React from 'react';
import { useDispatch } from 'react-redux';

// TODO: replace sample image for "No image uploaded" template
import sampleImage from '../../assets/sample-project-image.png';

import Card from '../UI/Containers/Card';
import Button from '../UI/Button';
import Modal from '../UI/Containers/Modal';
import OffsettingProjectForm from './OffsettingProjectForm';
import DeleteModal from './DeleteModal';

import useBoolean from '../../hooks/use-boolean';
import useAxios from '../../hooks/use-axios';

import { offsettingProjectsActions } from '../../store/offsetting-projects-slice';
import useNotification from '../../hooks/use-notification';

function OffsettingProject(props) {
    const dispatch = useDispatch();
    const { pushNotification } = useNotification();
    const { project } = props;
    const { boolean: isEditModalOpen, setTrue: openEditModal, setFalse: closeEditModal } = useBoolean();
    const { boolean: isDeleteModalOpen, setTrue: openDeleteModal, setFalse: closeDeleteModal } = useBoolean();
    const { sendRequest: deleteOffsettingProject } = useAxios();

    const handleUpdateOffsettingProject = (offsettingProject) => {
        dispatch(offsettingProjectsActions.updateCompanyOffsettingProject(offsettingProject));
        pushNotification({ message: `The offsetting project ${offsettingProject.project_name} has been updated` });
        closeEditModal();
    };

    const handleDeleteOffsettingProject = () => {
        const deleteOffsettingProjectRequest = {
            url: `/carbon_credit_batches/${project.id}`,
            method: 'delete',
            manageResponse: true,
        };
        deleteOffsettingProject(deleteOffsettingProjectRequest, (data) => {
            if (data.success === 'true') {
                dispatch(offsettingProjectsActions.removeCompanyOffsettingProject(project.id));
                closeDeleteModal();
            } else
                console.log('TODO: handle this case');
        });
    };

    const offsettingProjectFooter = props.companyProject ? (
        <div className="offsetting-project-card-footer">
            <Button
                onClick={openEditModal}
                style={{ width: '45%' }}
            >
                Edit
            </Button>
            {isEditModalOpen && 
            <Modal
                title="Edit project"
                width={40}
                onDismiss={closeEditModal}
                footer={<React.Fragment />}
            >
                <OffsettingProjectForm
                    onSuccess={handleUpdateOffsettingProject}
                    updateMode={true}
                    project={project}
                />
            </Modal>}

            <Button
                className="custom-button-danger"
                onClick={openDeleteModal}
                style={{ width: '45%' }}
            >Delete</Button>
            {isDeleteModalOpen && <DeleteModal onDismiss={closeDeleteModal} onSuccess={handleDeleteOffsettingProject} />}
        </div>
    ) : (
        <div className="offsetting-project-card-footer">
            <Button>
                Make offer
            </Button>
        </div>
    );

    return (
        <Card className="offsetting-project-card">
            <img src={project.image_path || sampleImage} width="100%" height="35%" />
            <div className="offsetting-project-card-body">
                <p className="offsetting-project-name">
                    {project.project_name}
                    <small className="offsetting-project-status">{project.status}</small>
                </p>
                <p className="offsetting-project-standard">{project.standard}</p>
                <p className="offsetting-project-description">{project.description}</p>
                <p className="offsetting-project-price">${project.price}</p>
                <p className="offsetting-project-amount">{`${project.amount} credits`}</p>
            </div>
            {offsettingProjectFooter}
        </Card>
    );
}

export default OffsettingProject;