import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';

import Label from '../UI/Text/Label';
import TextInput from '../UI/Inputs/TextInput';
import Button from '../UI/Button';

import useAxios from '../../hooks/use-axios';
import { companiesActions } from '../../store/companies-slice';

function NewCompanyForm(props) {
    const dispatch = useDispatch();
    const nameRef = useRef();
    const websiteRef = useRef();
    const addressRef = useRef();
    const activityRef = useRef();
    const { isLoading, error, sendRequest: createCompany } = useAxios();

    const handleOnSubmit = (event) => {
        event.preventDefault();
        const createCompanyRequest = {
            url: '/companies',
            method: 'post',
            params:{
                company: {
                    name: nameRef.current.value,
                    website: websiteRef.current.value,
                    address: addressRef.current.value,
                    activity: activityRef.current.value,
                }
            }
        };
        createCompany(createCompanyRequest, (data) => {
            if (data.success === 'true') {
                dispatch(companiesActions.addCompany(data.company));
                props.onSuccess();
            } else {
                // TODO: handle this case
            }
        });
    }

    return (
        <form onSubmit={handleOnSubmit}>
            <div className="add-company-inputs">
                <div className="add-company-input">
                    <Label htmlFor="name-input">Name*</Label>
                    <TextInput
                        id="name-input"
                        placeholder="Type the company name"
                        inputRef={nameRef}
                    />
                </div>
                <div className="add-company-input">
                    <Label htmlFor="website-input">Website</Label>
                    <TextInput
                        id="website-input"
                        placeholder="Type the company website"
                        inputRef={websiteRef}
                    />
                </div>
                <div className="add-company-input">
                    <Label htmlFor="address-input">Address*</Label>
                    <TextInput
                        id="address-input"
                        placeholder="Type company's address"
                        inputRef={addressRef}
                    />
                </div>
            </div>

            <div className="add-company-inputs">
                <div className="add-company-input">
                    <Label htmlFor="activity-input">Activity*</Label>
                    <TextInput
                        id="activity-input"
                        placeholder="Type company's activity"
                        inputRef={activityRef}
                    />
                </div>
            </div>

            <div className="add-company-submit">
                <p>*This field is mandatory</p>
                <Button type='submit'>Save</Button>
            </div>
        </form >
    )
}

export default NewCompanyForm;