import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import './TaskCategory.scss';
import three_dots from '../../assets/icons/three_dots.svg';

import Card from '../UI/Containers/Card';
import TextInput from '../UI/Inputs/TextInput';
import Button from '../UI/Button';
import AddTask from './AddTask';
import TaskItem from './TaskItem';
import QuickMenu from '../UI/Containers/QuickMenu';

import useAxios from '../../hooks/use-axios';
import useBoolean from '../../hooks/use-boolean';

function TaskCategory() {
    const addTaskRef = useRef();
    const newNameRef = useRef();
    const params = useParams();
    const navigate = useNavigate();
    
    const [id, setId] = useState();
    const [tasks, setTasks] = useState([]);
    const [potentialAssignees, setPotentialAssignees] = useState([]);

    const { isLoading, error, sendRequest: fetchTasks } = useAxios();
    const { sendRequest: updateCategory } = useAxios();
    const { boolean: editCategoryName, toggle: toggleEditCategoryName } = useBoolean();

    useEffect(() => {
        const taskCategoryRequest = {
            url: `/task_categories/${params.name}`,
        };
        fetchTasks(taskCategoryRequest, (data) => {
            setId(data.task_category.id);
            setTasks(data.task_category.tasks);
            setPotentialAssignees(data.potential_assignees);
        });
    }, [fetchTasks]);

    const handleUpdateCategory = () => {
        const updateCategoryRequest = {
            url: `/task_categories/${params.name}`,
            method: 'post',
            params: {
                task_category: { name: newNameRef.current.value }
            },
            manageResponse: true,
        };
        updateCategory(updateCategoryRequest, (data) => {
            if (data.success === 'true') {
                toggleEditCategoryName();
                navigate(`/tasks/${newNameRef.current.value}`);
            }
        });
    };

    const addTaskProps = {
        categoryID: id,
        categoryName: params.name,
        potentialAssignees: potentialAssignees,
        buttonClass: "quick-menu-item",
        dismiss: true,
        modalRef: addTaskRef
    };

    const actionItems = [
        { text: 'Edit category name', onClick: toggleEditCategoryName },
        { text: 'Delete category', disabled: tasks.length > 0, onClick: () => {/* TODO: Pass a function for deleting the task category */} },
        { text: 'Add new task', customItem: AddTask, props: addTaskProps, ref: addTaskRef }
    ];

    return (
        <Card className="task-category-card">
            {editCategoryName ? (
                <div className="task-category-header">
                    <TextInput 
                        inputRef={newNameRef}
                        value={params.name}
                    />
                    <Button onClick={handleUpdateCategory}>Save</Button>
                </div>
            ) : (
                <div className="task-category-header">
                    <h3>{params.name}</h3>
                    <QuickMenu items={actionItems} icon={three_dots} direction="left" />
                </div>
            )}
            <div className="task-category-tasks">
                {tasks.length === 0 && (
                    <p>There are no tasks yet for this category.</p>
                )}
                {tasks.map((task) => (
                    <Card className="task-item" key={task.id} >
                        <TaskItem
                            task={task}
                        />
                    </Card>
                ))}
            </div>
        </Card>
    );
}

export default TaskCategory;