import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import './Scope.scss';

import ScopeSection from './ScopeSection';
import Questions from './Questions';
import Question from './Question';
import ScopeSectionComponent from './ScopeSectionComponent';
import Button from '../../../UI/Button';

import useAxios from '../../../../hooks/use-axios';
import { GHGInventoryActions } from '../../../../store/ghg-inventory-slice';
import { projectsActions } from '../../../../store/projects-slice';

function Scope(props) {
    const dispatch = useDispatch();
    const { sendRequest: fetchGHGInventoryStructure } = useAxios();
    const scope = useSelector((state) => (state.GHGInventory[props.scope]));
    const scopeID = props.scopeID;
    const project = useSelector((state) => state.projects.currentProject);
    const projectScope = project?.ghg_inventory.scopes[scopeID];

    useEffect(() => {
        if (!scope) {
            fetchGHGInventoryStructure({ url: '/ghg_inventory_structure' }, (data) => {
                dispatch(GHGInventoryActions.saveGHGInventoryStructure(data));
            });
        }
    }, [fetchGHGInventoryStructure]);

    const updateSectionField = (sectionID, questionID, value, index) => {
        dispatch(projectsActions.updateCurrentProjectScopeField({
            scopeID, sectionID, questionID, value, index
        }));
    };

    const addSection = (event) => {
        const sectionID = event.target.dataset.sectionId;
        const sectionName = event.target.dataset.sectionName;
        const sectionInitialContent = { 'label': null };
        scope[sectionName].questions.forEach((question) => {
            sectionInitialContent[question.id] = null;
        });

        dispatch(projectsActions.pushCurrentProjectScopeSection({
            scopeID, sectionID, sectionInitialContent
        }));
    }

    if (!scope) { // TODO: improve the loading screen
        return (
            <div>Loading...</div>
        );
    }

    const renderScopeSectionContent = (sectionName, section, projectSection, index = null) => {
        return (
            <React.Fragment>
                {section.header_components && (
                    <div className={`${scope.header_layout || 'horizontal'}-scope-section-header`}>
                        {section.header_components.map((component) => (
                            <ScopeSectionComponent
                                key={component.id}
                                type={component.type}
                                component={component}
                            />
                        ))}
                    </div>
                )}
                <Questions
                    className="scope-questions"
                    section={sectionName}
                    sectionID={section.id}
                    index={index}
                    layout={section.layout}
                    label={section.allow_label ? `${sectionName}-label` : null }
                    labelValue={projectSection['label']}
                    onLabelUpdate={updateSectionField}
                >
                    {section.questions.length == 0 && <p>Coming soon...</p>}
                    {section.questions.map((question) => (
                        <Question
                            key={question.id}
                            question={question}
                            sectionID={section.id}
                            scope={props.scope}
                            index={index}
                            value={projectSection[question.id]}
                            onUpdate={updateSectionField}
                        />
                    ))}
                </Questions>
            </React.Fragment>
        );
    }

    return (
        <form className="scope-form">
            {scope.no_sections && renderScopeSectionContent(scope.name, scope, projectScope)}
            {scope.sections && scope.sections.map((sectionName) => {
                const section = scope[sectionName];
                
                return (
                    <ScopeSection section={sectionName} key={section.id}>
                        {!section.array && renderScopeSectionContent(sectionName, section, projectScope[section.id])}
                        {section.array && (
                            <React.Fragment>
                                {projectScope[section.id].map((entry, index) => (
                                    <React.Fragment key={index}>
                                        {renderScopeSectionContent(sectionName, section, entry, index)}
                                    </React.Fragment>
                                ))}
                                <Button
                                    onClick={addSection}
                                    data-section-id={section.id}
                                    data-section-name={sectionName}
                                >
                                    {section.add_entry_text}
                                </Button>
                            </React.Fragment>
                        )}
                    </ScopeSection>
                );
            })}
        </form>
    );
}

export default Scope;