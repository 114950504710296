import React from 'react';
import ReactDOM from 'react-dom';
import { useSelector } from 'react-redux';

import List from '../Data/List';
import Notification from './Notification';

function Notifications() {
    const notifications = useSelector((state) => state.notifications.notifications);

    return ReactDOM.createPortal(
        <List
            className="notification"
            items={notifications}
            itemElement={Notification}
            emptyMessage={<></>}
        />,
        document.getElementById('notifications-root')
    );
}

export default Notifications;