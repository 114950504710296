import React, { useState, useEffect } from 'react';
import './Projects.scss';

import ProjectsFilters from './ProjectsFilters';
import ProjectItem from './ProjectItem';
import List from '../UI/Data/List';

import useAxios from '../../hooks/use-axios';
import AddProject from './AddProject';

const listEmptyMessage = 'We could not find any project';

function Projects() {
    const [projects, setProjects] = useState([]);
    const [potentialOwners, setPotentialOwners] = useState([]);
    const [filters, setFilters] = useState({ name: '' });
    const { isLoading, error, sendRequest: fetchProjects } = useAxios();

    useEffect(() => {
        const projectsIndexRequest = {
            url: '/projects',
            params: {
                filters,
            }
        };
        fetchProjects(projectsIndexRequest, (data) => {
            setProjects(data.projects);
            setPotentialOwners(data.potential_owners);
        });
    }, [fetchProjects, filters]);

    const handleFiltersChange = (newFilters) => {
        setFilters((oldFilters) => ({ ...oldFilters, ...newFilters }));
    }

    return (
        <React.Fragment>
            <AddProject potentialOwners={potentialOwners} />
            <ProjectsFilters onFiltersChange={handleFiltersChange} />
            <div className="projects-list">
                <List 
                    model="project"
                    items={projects}
                    itemElement={ProjectItem}
                    emptyMessage={listEmptyMessage}
                />
            </div>
        </React.Fragment>
    );
}

export default Projects;