import React, { useEffect, useState } from 'react';

import cross from '../../../assets/icons/cross.svg';
import fileIcon from '../../../assets/icons/file.svg'; 

import Card from '../../UI/Containers/Card';
import Icon from '../Icon';

function FileInput(props, ref) {
    const [files, setFiles] = useState([]);

    useEffect(() => {
        if (ref) ref.current = files;
        if (props.onChange) props.onChange(files);
    }, [files]);
    
    const uploadFile = () => {
        document.getElementById(props.id).click();      
    };

    const addFiles = (event) => {
        const newFiles = Array.from(event.target.files);
        if (props.multiple)
            setFiles((oldFiles) => (oldFiles.concat(newFiles)));
        else
            setFiles(newFiles);
        document.getElementById(props.id).value = ""; // TODO: check if we need to reset the input file

        if (props.onUpload) props.onUpload(newFiles);
    }

    const removeFile = (event) => {
        const fileToBeDeleted = files[event.target.dataset.index];
        setFiles((oldFiles) => (oldFiles.toSpliced(event.target.dataset.index, 1)));

        if (props.onRemove) props.onRemove(fileToBeDeleted);
    };

    const filesSelectedStyle = files.length > 0 && {
        backgroundColor: '#D8E4DA',
    };

    return (
        <Card
            className={props.className || 'file-input'}
            style={filesSelectedStyle || {}}
            divRef={ref}
        >
            <div className={props.headerClass || 'file-input-header'} onClick={uploadFile}>
                <input 
                    type="file" 
                    id={props.id} 
                    hidden
                    multiple={props.multiple} 
                    accept={props.accept}
                    onChange={addFiles}
                />
                {props.placeholder || 'Upload files'}
                <Icon src={props.icon || fileIcon} />
            </div>           
            {files.length > 0 && <hr />}
            {files.map((file, index) => (
                <div className={props.fileEntryClass || 'file-entry'} key={index}>
                    <b>{file.name}</b>
                    <Icon src={cross} onClick={removeFile} data-index={index} />
                </div>
            ))}
        </Card>
    );
}

export default React.forwardRef(FileInput);