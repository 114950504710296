import React from 'react';
import { useSelector } from 'react-redux';

import './Profile.scss';
import user_dark from '../../assets/icons/user_dark.svg';
import download from '../../assets/icons/download.svg';

import UserForm from './UserForm';
import Icon from '../UI/Icon';

function Profile(props) {
    const user = useSelector((state) => state.auth.user);

    if (!user) return <></>;

    return (
        <React.Fragment>
            <p className="profile-title">My Profile</p>
            <div className="profile-photo-container">
                <Icon src={user_dark} className="profile-photo" />
                <p>Upload a new photo</p>
                <Icon src={download} />
            </div>
            <UserForm user={user} />
        </React.Fragment>
    );
}

export default Profile;