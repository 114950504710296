import { useState } from "react";

function useBoolean() {
    const [boolean, setBoolean] = useState(false);

    const setTrue = () => {
        setBoolean(true);
    };

    const setFalse = () => {
        setBoolean(false);
    };

    const toggle = () => {
        setBoolean(!boolean);
    };

    return {
        boolean,
        setTrue,
        setFalse,
        toggle,
    };
}

export default useBoolean;