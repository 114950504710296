import React from 'react';

import arrow_up from '../../../../assets/icons/arrow_up';
import arrow_down from '../../../../assets/icons/arrow_down';

import Card from '../../../UI/Containers/Card';
import Icon from '../../../UI/Icon';

import useBoolean from '../../../../hooks/use-boolean';

function ScopeSection(props) {
    const { boolean: sectionDropdown, toggle: toggleSectionDropdown } = useBoolean();

    return (
        <Card className="scope-section">
            <div className="scope-section-header">
                <p>{props.section}</p>
                <Icon
                    src={sectionDropdown ? arrow_up : arrow_down}
                    onClick={toggleSectionDropdown}
                    className="dropdown-icon"
                />
            </div>
            {sectionDropdown && (
                <div className="scope-section-content">
                    {props.children}
                </div>
            )}
        </Card>
    );
}

export default ScopeSection;