import { createSlice } from '@reduxjs/toolkit';

const initialNotificationsState = {
    notifications: [],
};
const notificationsSlice = createSlice({
    name: 'notifications',
    initialState: initialNotificationsState,
    reducers: {
        pushNotification(state, action) {
            state.notifications.push(action.payload);
        },
        pushNotifications(state, action) {
            state.notifications = state.notifications.concat(action.payload);
        },
        removeNotification(state, action) {
            if (action.payload.id) { // Remove based on notification ID
                const index = state.notifications.findIndex(
                    (notification) => (notification.id === action.payload.id)
                );
                if (index >= 0) state.notifications.splice(index, 1);
            } else // Remove based on notification index
                state.notifications.splice(action.payload, 1);
        },
        clearNotifications(state) {
            state.notifications = [];
        },
    }
});

export const notificationsActions = notificationsSlice.actions;
export default notificationsSlice.reducer;