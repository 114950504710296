import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import ReactDOM from 'react-dom';

import './Loading.scss';

import { initialRoutePath } from '../../tools/auth-helper';

// TODO: Thinks of a better way of calling this component (route /loading is kind of ugly)
// TODO: add timeout to loading screen
function Loading() {
    const location = useLocation();
    const navigate = useNavigate();
    const currentUser = useSelector((state) => (state.auth.user));

    useEffect(() => {
        if (currentUser)
            navigate(location.state?.redirectPath || initialRoutePath(currentUser.role));
    }, [currentUser]);

    const loader = (
        <div className="loader-background">
            <div className="loader" />
        </div>
    );

    return (
        <React.Fragment>
            {ReactDOM.createPortal(
                loader,
                document.getElementById('backdrop-root')
            )}
        </React.Fragment>
    );
}

export default Loading;