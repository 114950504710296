import { useState, useCallback } from 'react';
import axios from 'axios';

function useAxios() {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const sendRequest = useCallback(async (config, callback) => {
        setIsLoading(true);
        setError(null);
        try {
            axios.defaults.headers.common["X-CSRF-Token"] = document
                .querySelector('meta[name="csrf-token"]')
                .getAttribute('content');
            const response = await axios({
                url: config.url,
                method: config.method || 'get',
                headers: config.headers || {},
                params: config.params || {},
                data: config.data || {},
                paramsSerializer: config.paramsSerializer || {},
            });

            if (response.data.success !== 'true' && config.manageResponse !== true)
                throw new Error(response.data.reason || 'Something went wrong');

            callback(response.data);
        } catch (error) {
            setError(error.message);
        }
        setIsLoading(false);
    }, []);

    return {
        isLoading,
        error,
        sendRequest
    };
}

export default useAxios;