import React, { useState, useEffect } from 'react';

import './Tasks.scss';

import TaskCategoryItem from './TaskCategoryItem';
import List from '../UI/Data/List';
import TaskItem from './TaskItem';
import AddTaskCategory from './AddTaskCategory';

import useAxios from '../../hooks/use-axios';

const customEmptyList = (
    <p>There are no tasks yet for this category.</p>
);

function Tasks() {
    const [tasks, setTasks] = useState({});
    const [categories, setCategories] = useState([]);
    const [potentialAssignees, setPotentialAssignees] = useState([]);
    const { isLoading, error, sendRequest: fetchTasks } = useAxios();

    useEffect(() => {
        const tasksIndexRequest = {
            url: '/tasks_index',
        };
        fetchTasks(tasksIndexRequest, (data) => {
            setTasks(data.tasks);
            setCategories(data.task_categories);
            setPotentialAssignees(data.potential_assignees);
        });
    }, [fetchTasks]);

    return (
        <div className="task-categories">
            {categories.map((category, index) => (
                <TaskCategoryItem
                    key={category.id}
                    index={index}
                    name={category.name}
                    id={category.id}
                    potentialAssignees={potentialAssignees}
                    tasksCount={tasks[category.id]?.length || 0}
                >
                    {<List 
                        model="task"
                        className="task-item"
                        items={tasks[category.id]?.slice(0, 3) || []}
                        itemElement={TaskItem}
                        customEmptyList={customEmptyList}
                    />}
                </TaskCategoryItem>
            ))}
            <AddTaskCategory />
        </div>
    );
}

export default Tasks;