import React from 'react';

import './Questions.scss';

import Card from '../../../UI/Containers/Card';
import Label from '../../../UI/Text/Label';
import TextInput from '../../../UI/Inputs/TextInput';

function Questions(props) {
    const handleLabelChange = (value) => {
        props.onLabelUpdate(props.sectionID, 'label', value, props.index);
    };

    return (
        <Card className={props.className}>
            {props.label && (
                <div className="subsection-label">
                    <Label htmlFor={props.label}>Do you want to label this {props.section.toLowerCase()}?</Label>
                    <TextInput
                        id={props.label}
                        value={props.labelValue}
                        className="subsection-label-input"
                        debouncingTime={800}
                        onChange={handleLabelChange}
                    />
                </div>
            )}
            <div className={`subsection-questions-${props.layout || 'stack-of-four'}`}>
                {props.children}
            </div>
        </Card>
    );
}

export default Questions;