import React from 'react';

import dashboard_sample from '../../assets/dashboard.png';

function Dashboard() {
    return (
        <React.Fragment>
            <img src={dashboard_sample} className="dashboard-sample" />
        </React.Fragment>
    );
}

export default Dashboard;