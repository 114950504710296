import React, { useState } from 'react';

import icons from '../icons';

import Icon from '../Icon';

function Tooltip(props) {
    let timeout;
    const [active, setActive] = useState(false);
  
    const showTip = () => {
      timeout = setTimeout(() => {
        setActive(true);
      }, props.delay || 400);
    };
  
    const hideTip = () => {
      clearInterval(timeout);
      setActive(false);
    };
  
    return (
      <div
        className="tooltip-wrapper"
        onMouseEnter={showTip}
        onMouseLeave={hideTip}
      >
        {props.children}
        {props.icon && (
            <Icon src={icons[props.icon]} className="tooltip-icon" />
        )}
        {active && (
          <div className={`tooltip ${props.direction || 'top'}`}>
            {props.content}
          </div>
        )}
      </div>
    );
}

export default Tooltip;