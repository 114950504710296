import React from 'react';

import arrow_up from '../../../assets/icons/arrow_up.svg';
import arrow_down from '../../../assets/icons/arrow_down.svg';

import Card from '../../UI/Containers/Card';
import Checkbox from '../../UI/Inputs/Checkbox';
import Icon from '../../UI/Icon';
import Scope from './Scopes/Scope';

import useBoolean from '../../../hooks/use-boolean';

function ScopesSection() {
    const { boolean: scope1Dropdown, toggle: toggleScope1Dropdown} = useBoolean();
    const { boolean: scope2Dropdown, toggle: toggleScope2Dropdown} = useBoolean();
    const { boolean: scope3Dropdown, toggle: toggleScope3Dropdown} = useBoolean();

    return (
        <React.Fragment>
            <Card className='scope-card'>
                <div className='scope-header'>
                    <Checkbox className="scope-header-checkbox">Scope 1</Checkbox>
                    <Icon
                        src={scope1Dropdown ? arrow_up : arrow_down}
                        onClick={toggleScope1Dropdown}
                        className="dropdown-icon"
                    />
                </div>
                {scope1Dropdown && <Scope scope="scope1" scopeID={1} />}
            </Card>
            <Card className='scope-card'>
                <div className='scope-header'>
                    <Checkbox className="scope-header-checkbox">Scope 2</Checkbox>
                    <Icon
                        src={scope2Dropdown ? arrow_up : arrow_down}
                        onClick={toggleScope2Dropdown}
                        className="dropdown-icon"
                    />
                </div>
                {scope2Dropdown && <Scope scope="scope2" scopeID={2} />}
            </Card>
            <Card className='scope-card'>
                <div className='scope-header'>
                    <Checkbox className="scope-header-checkbox">Scope 3</Checkbox>
                    <Icon
                        src={scope3Dropdown ? arrow_up : arrow_down}
                        onClick={toggleScope3Dropdown}
                        className="dropdown-icon"
                    />
                </div>
                {scope3Dropdown && <Scope scope="scope3" scopeID={3} />}
            </Card>
        </React.Fragment>
    );
}

export default ScopesSection;