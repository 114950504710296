import React from 'react';
import { useSelector } from 'react-redux';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import Login from '../components/Login/Login';
import Layout from '../components/Layout/Layout';
import AccessDenied from '../components/Layout/AccessDenied';

import { sharedRoutes } from '../tools/router-helper';
import { currentUserRoutes } from '../tools/auth-helper';

function App() {
    const currentUser = useSelector((state) => state.auth.user);

    const router = createBrowserRouter([
        { path: '/login', element: <Login /> },
        {
            path: '/',
            element: <Layout />,
            children: sharedRoutes.concat(currentUserRoutes(currentUser?.role)),
            errorElement: <AccessDenied />,
        }
    ]);

    return <RouterProvider router={router} />;
}

export default App;