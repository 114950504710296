import React from 'react';
import './NavBarTabs.scss';

import Card from '../UI/Containers/Card';
import NavBarTab from './NavBarTab';

import useBoolean from '../../hooks/use-boolean';

function NavBarTabs(props) {
    return (
        <Card className="transparent-card">
            <p className="nav-bar-title">{props.title}</p>
            <ul className="nav-bar-tabs">
                {props.tabs.map((tab) => {
                    if (tab.children) {
                        const { boolean: parentTabClicked, toggle: toggleDropdown} = useBoolean();
                        const isParentActive = tab.children.some((childTab) => (childTab.name === props.activeTab));

                        return (
                            <React.Fragment key={tab.name}>
                                <NavBarTab
                                    name={tab.name}
                                    icon={tab.icon}
                                    isParent={true}
                                    onClick={toggleDropdown}
                                    childrenShown={parentTabClicked}
                                    isActive={!parentTabClicked && isParentActive}
                                />
                                {parentTabClicked && (
                                    <NavBarTabs
                                        tabs={tab.children}
                                        activeTab={props.activeTab}
                                    />
                                )}
                            </React.Fragment>
                        )
                    } else {
                        return (
                            <NavBarTab
                                key={tab.name}
                                name={tab.name}
                                link={tab.link}
                                icon={tab.icon}
                                isActive={props.activeTab === tab.name}
                                onActiveTabChange={props.onActiveTabChange}
                            />
                        );
                    }
                })}
            </ul>
        </Card>
    );
}

export default NavBarTabs;