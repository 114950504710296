import React from 'react';

import './Subsidiaries.scss';

import Card from '../../../UI/Containers/Card';
import Subsidiary from './Subsidiary';
import Button from '../../../UI/Button';

function Subsidiaries(props) {
    const handleOnChange = (index, subsidiary) => {
        const subsidiaries = [...props.subsidiaries];
        subsidiaries[index] = subsidiary;
        props.onChange(props.id, subsidiaries)
    }

    const addNewSubsidiary = () => {
        props.onChange(props.id, props.subsidiaries.concat({ company_name: '', ownership_percentage: '' }));
    }

    return (
        <Card className='subsidiaries-card'>
            <h3>Subsidiary</h3>
            {props.subsidiaries.map((subsidiary, index) => (
                <Subsidiary
                    key={index}
                    index={index}
                    companyName={subsidiary.company_name}
                    ownershipPercentage={subsidiary.ownership_percentage}
                    onChange={handleOnChange}
                />
            ))}
            <div className="add-subsidiary">
                <Button
                    onClick={addNewSubsidiary}
                    className="add-subsidiary-button custom-button"
                >
                    Add Subsidiary
                </Button>
            </div>
        </Card>
    );
}

export default Subsidiaries;