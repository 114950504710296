import { useDispatch } from "react-redux";

import { notificationsActions } from '../store/notifications-slice';

function useNotification() {
    const dispatch = useDispatch();

    const pushNotification = (notification, timeout = 5) => {
        const id = Date.now();
        dispatch(notificationsActions.pushNotification({ ...notification, id }));

        if (timeout !== 0)
            setTimeout(() => {
                dispatch(notificationsActions.removeNotification({ id }));
            }, timeout * 1000);
    };

    const clearNotifications = () => {
        dispatch(notificationsActions.clearNotifications());
    };

    return {
        pushNotification,
        clearNotifications,
    };
}

export default useNotification;