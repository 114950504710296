import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';

import NavBar from '../NavBar/NavBar';
import AppHeader from "../AppHeader/AppHeader";
import Notifications from '../UI/Notifications/Notifications';

import { authActions } from '../../store/auth-slice';
import { uiActions } from '../../store/ui-slice';
import { identifyCurrentTab, getPageTitle } from '../../tools/ui-helper';

function Layout() {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const activeTab = useSelector((state) => state.ui.activeTab);

    useEffect(() => {
        async function checkSession() {
            try {
                var currentUser;
                const currentUserID = localStorage.getItem('userID');
                if (currentUserID) {
                    const response = await axios.get(`/users/${currentUserID}`);
                    currentUser = response.data.user;
                    dispatch(authActions.saveUser(currentUser));
                }

                if (!currentUser)
                    navigate('/login');
            } catch (error) {
                navigate('/login');
            }
        }

        checkSession();
    }, []);

    useEffect(() => {
        dispatch(uiActions.saveActiveTab(identifyCurrentTab()));
    }, [location]);

    const handleLogout = async () => {
        localStorage.removeItem('userID');
        await axios.get('/logout');
        navigate('/login');
    };

    return (
        <React.Fragment>
            <NavBar />
            <Notifications />
            <AppHeader onLogout={handleLogout} />
            <div className="page-container">
                <div className="page-header">
                    <p className="page-title">{getPageTitle(activeTab)}</p>
                </div>
                <Outlet />
            </div>
        </React.Fragment>
    );
}

export default Layout;