import React, { useState, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';

import Card from '../UI/Containers/Card';
import AddTask from './AddTask';
import QuickMenu from '../UI/Containers/QuickMenu';

function TaskCategoryItem(props) {
    const navigate = useNavigate();
    const addTaskRef = useRef();
    const [name, setName] = useState(props.name);
    const taskCategoryPath = `/tasks/${name}`;

    const addTaskProps = {
        categoryID: props.id,
        categoryName: name,
        potentialAssignees: props.potentialAssignees,
        buttonClass: "quick-menu-item",
        dismiss: true,
        modalRef: addTaskRef
    };

    const actionItems = [
        { text: 'View whole category', onClick: () => { navigate(taskCategoryPath, { state: { name, id: props.id } }); }},
        { text: 'Delete category', disabled: props.tasksCount > 0, onClick: () => {/* TODO: Pass a function for deleting the task category */} },
        { text: 'Add new task', customItem: AddTask, props: addTaskProps, ref: addTaskRef }
    ];

    return (
        <Card className="task-category-item-card">
            <div className="task-category-header">
                <h3>{name}</h3>
                <QuickMenu
                    items={actionItems}
                    direction={(props.index + 1) % 4 == 0 ? 'left' : 'right'}
                />
            </div>
            <div className="task-category-body">
                {props.children}
            </div>
            <div className="task-category-footer">
                {props.tasksCount > 3 && (
                    <Link to={taskCategoryPath} state={{ name, id: props.id }}>
                        <p className="task-category-item-p">
                            This category has {props.tasksCount - 3} more {props.tasksCount - 3 > 1 ? 'tasks' : 'task'}.
                            Click here to see the whole category...
                        </p>
                    </Link>
                )}
                <AddTask
                    categoryID={props.id}
                    categoryName={name}
                    potentialAssignees={props.potentialAssignees}
                />
            </div>
        </Card>
    );
}

export default TaskCategoryItem;