import React, { useState, useEffect } from 'react';

import RadioButton from './RadioButton';
import Tooltip from '../Text/Tooltip';
import TextInput from './TextInput';

import { validateUpcasedFirstCharacter } from '../../../tools/input-validators';

function RadioButtonGroup(props) {
    const optionValues = props.options.map((option) => (option.value || option));
    const isOther = optionValues.indexOf(props.value) === -1;
    const [selectedOption, setSelectedOption] = useState(isOther ? 'other' : props.value || null );
    const [otherValue, setOtherValue] = useState(isOther ? props.value || '': '');

    if (props.debouncingTime && props.onChange) {
        useEffect(() => {
            const id = setTimeout(() => {
                props.onChange(selectedOption === 'other' ? otherValue : selectedOption);
            }, props.debouncingTime);
            return () => {
                clearTimeout(id);
            };
        }, [selectedOption]);
    }

    const selectOption = (option) => {
        setSelectedOption(option);
    };

    const typeOption = (option) => {
        setOtherValue(option);
        if (props.onChange)
            props.onChange(option);
    }

    // TODO: move tooltip logic into RadioButton.jsx
    return (
        <React.Fragment>
            {props.options.map((option) => {
                const value = option.value ? option.value : option;

                return (
                    <RadioButton
                        key={value}
                        onSelectOption={selectOption}
                        value={value}
                        className={props.className}
                        selected={selectedOption === value}
                    >
                        {value}
                        {option.tooltip && (
                            <Tooltip
                                content={option.tooltip}
                                icon={option.tooltip_icon}
                                direction={option.tooltip_direction}
                            />
                        )}
                    </RadioButton>
                );
            })}
            {props.otherAvailable && (
                <React.Fragment>
                    <RadioButton
                        onSelectOption={selectOption}
                        value="other"
                        className={props.className}
                        selected={selectedOption === 'other'}
                    >
                        Other:
                    </RadioButton>
                    <TextInput
                        className={props.otherClassName}
                        validationFunction={validateUpcasedFirstCharacter}
                        debouncingTime={props.debouncingTime}
                        onChange={typeOption}
                        value={otherValue}
                        disabled={selectedOption !== 'other'}
                    />
                </React.Fragment>
            )}
        </React.Fragment>
    );
}

export default RadioButtonGroup;