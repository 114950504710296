import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import './Task.scss';

import Label from '../UI/Text/Label';
import EditTask from './EditTask';
import AddComment from './AddComment';
import List from '../UI/Data/List';
import TaskComment from './TaskComment';

import useAxios from '../../hooks/use-axios';
import { formatDate } from '../../tools/date-helper';

function Task() {
    const params = useParams();
    const [task, setTask] = useState({});
    const [comments, setComments] = useState([]);
    const [potentialAssignees, setPotentialAssignees] = useState([]);
    const { isLoading, sendRequest: fetchTask } = useAxios();

    const handleTaskUpdate = () => {
        const fetchTaskRequest = {
            url: `/show_task/${params.id}`,
        };

        fetchTask(fetchTaskRequest, (data) => {
            setTask(data.task);
            setComments(data.comments);
            setPotentialAssignees(data.potential_assignees);
        });
    };

    useEffect(() => {
        handleTaskUpdate();
    }, [fetchTask]);

//  TODO: implement a standard loading screen
//    if (isLoading)
//        return <Loading />

    return (
        <div className="task-container">
            <div className="task-header">
                <p className="task-title">{`${task.category_name} > ${task.title}`}</p>
                <EditTask
                    categoryName={task.category_name}
                    task={task}
                    potentialAssignees={potentialAssignees}
                    onSuccess={handleTaskUpdate}
                />
            </div>
            <div className="task-fields">
                <div className="task-description-container">
                    <Label>Description</Label>
                    <div className="task-description">
                        {task.description}
                    </div>
                </div>
                <div className="task-fields-group">
                    <div className="task-field-container">
                        <Label>Assignee</Label>
                        <div className="task-field">{task.assignee?.full_name || 'Not assigned'}</div>
                    </div>
                    <div className="task-field-container">
                        <Label>Created by</Label>
                        <div className="task-field">{task.creator?.full_name}</div>
                    </div>
                    <div className="task-field-container">
                        <Label>Delivery Date</Label>
                        <div className="task-field">{formatDate(task.delivery_date) || 'Not assigned'}</div>
                    </div>
                </div>
            </div>
            <div className="task-comments">
                <div className="task-comments-header">
                    <p>Comments</p>
                    <AddComment
                        taskID={params.id}
                        taskTitle={task.title}
                        onSuccess={handleTaskUpdate}
                    />
                </div>
                <hr />
                <List
                    className="task-comment"
                    model="comment"
                    items={comments}
                    itemElement={TaskComment}
                    emptyMessage="There are not any comments yet."
                />
            </div>
        </div>
    );
}

export default Task;