import React, { useState, useEffect } from 'react';

import Icon from '../../UI/Icon';

function Input(props) { // TODO: if getting icon layout issues, replace Fragment with a div position: relative
    const [value, setValue] = useState(props.value || '');
    const classes = 'custom-input ' + props.className;

    if (props.debouncingTime && props.onChange) {
        useEffect(() => {
            const id = setTimeout(() => {
                props.onChange(value);
            }, props.debouncingTime);
            return () => {
                clearTimeout(id);
            };
        }, [value]);
    }

    const valueChangeHandler = (event) => {
        let value = event.target.value;
        if (props.validationFunction) {
            value = props.validationFunction(value); // TODO: Don´t call setValue if the new value has not changed
        }
        setValue(value);
    };

    return (
        <React.Fragment>
            {props.icon && <Icon className="custom-input-icon" src={props.icon} />}
            <input
                id={props.id}
                type={props.type || 'text'}
                className={classes}
                placeholder={props.placeholder}
                onChange={valueChangeHandler}
                ref={props.inputRef}
                disabled={props.disabled}
                value={value}
            />
        </React.Fragment>
    );
}

export default Input;