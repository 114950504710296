import React from 'react';

import Modal from '../../../UI/Containers/Modal';

function GlobalWarmingPotentialModal(props) {
    return (
        <Modal
            onDismiss={props.onDismiss}
            footer={<React.Fragment />}
        >
            <div className="standard-ghg-body">
                <p className="introduction-header">
                    Global Warning Potential
                </p>
                <ul>
                    <li className="bullet-list-item">
                        Table 1. GWPs of Common Greenhouse Gases and Refrigerants
                    </li>
                </ul>
                <table className="standard-ghg-table">
                    <tbody>
                        <tr>
                            <th>Gas or Blend</th>
                            <th>GWP</th>
                            <th>Source</th>
                        </tr>
                        <tr>
                            <td className="standard-ghg-table-first-td">CO2*</td>
                            <td>1</td>
                            <td>IPCC Second Assessment Report (1995)</td>
                        </tr>
                        <tr>
                            <td className="standard-ghg-table-first-td">CH4*</td>
                            <td>210</td>
                            <td>IPCC Second Assessment Report (1995)</td>
                        </tr>
                        <tr>
                            <td className="standard-ghg-table-first-td">HFC-23</td>
                            <td>310</td>
                            <td>IPCC Second Assessment Report (1995)</td>
                        </tr>
                        <tr>
                            <td className="standard-ghg-table-first-td">HFC-32</td>
                            <td>11.700</td>
                            <td>IPCC Second Assessment Report (1995)</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </Modal>
    );
}

export default GlobalWarmingPotentialModal;