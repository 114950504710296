import React from 'react';
import { useSelector } from 'react-redux';

import OffsettingProject from './OffsettingProject';
import AddOffsettingProject from './AddOffsettingProject';

function OffsettingProjects(props) {
    const projectsType = props.companyProject ? 'companyOffsettingProjects' : 'offsettingProjects';
    const offsettingProjects = useSelector((state) => (state.offsettingProjects[projectsType]));

    return (
        <React.Fragment>
            {props.companyProject && (
                <AddOffsettingProject />
            )}
            <div className="offsetting-projects">
                {offsettingProjects.map((project) => (
                    <OffsettingProject
                        key={project.id}
                        project={project}
                        companyProject={props.companyProject}
                    />
                ))}
            </div>
        </React.Fragment>
    );
}

export default OffsettingProjects;