// Validation functions will receive a string as input an return the string 
// without undesired characters.

function chooseValidationFunction(category) {
    switch (category) {
        case 'integer':
            return validateInteger;
        case 'real':
            return validateReal;
        case 'percentage':
            return validatePercentage;
        case 'upcased-first-character':
            return validateUpcasedFirstCharacter;
        default:
            return null;
    }
}

export function validateInteger(value) {
    return value.replace(/[^0-9]/gi, '');
}

export function validateReal(value) {
    const result = value.replace(/[^0-9.]/gi, '');

    const dotIndex = result.indexOf('.');
    if (dotIndex > -1)
        return result.slice(0, dotIndex + 1) + result.slice(dotIndex + 1).replaceAll('.', '');
    else
        return result;
}

export function validatePercentage(value) {
    const result = value.replace(/[^0-9.]/gi, '');

    if (result.slice(0, 3) === '100') return '100';

    const dotIndex = result.indexOf('.');
    if (dotIndex > -1)
        return result.slice(0, dotIndex) + '.' + result.slice(dotIndex + 1).replaceAll('.', '').slice(0, 2);
    else
        return result.slice(0, 2);
}

export function validateUpcasedFirstCharacter(value) {
    return value.charAt(0).toUpperCase() + value.slice(1);
}

export default chooseValidationFunction;