import React from 'react';

function ResultsSection() {
    return (
        <React.Fragment>
            Under development
        </React.Fragment>
    );
}

export default ResultsSection;