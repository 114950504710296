import React from 'react';
import { useSelector } from 'react-redux';

import './NavBar.scss';

import whiteLogo from '../../assets/white-logo.svg';

import Icon from '../UI/Icon';
import NavBarTabs from './NavBarTabs';

import { currentUserTabs } from '../../tools/auth-helper';

function NavBar() {
    const activeTab = useSelector((state) => state.ui.activeTab);
    const currentUser = useSelector((state) => state.auth.user);

    return (
        <div className="nav-bar">
            <Icon className="white-logo" src={whiteLogo} />
            <NavBarTabs
                title="MENU"
                tabs={currentUserTabs(currentUser?.role)}
                activeTab={activeTab}
            />
        </div>
    );
}

export default NavBar;