import React from 'react';
import ReactDOM from 'react-dom';

import './Product.scss';
import check from '../../assets/icons/check.svg';
import selectedRadioButton from '../../assets/icons/selectedRadioButton.svg';
import unselectedRadioButton from '../../assets/icons/unselectedRadioButton.svg';

import Icon from '../UI/Icon';
import Card from '../UI/Containers/Card';
import Button from '../UI/Button';

function Product(props) {
    const defaultProduct = (
        <Card className="product-card product-card-dark">
            <h2 className="product-title">
                Carbon Ally Platform
            </h2>
            <h2 className="product-subtitle">
                Hired
            </h2>
        </Card>
    );

    const handleOnClick = () => {
        if (!props.selected)
            props.onSelectProduct(props.title);
    }

    const extraProduct = (
        <React.Fragment>
            <Card
                className={`product-card product-card-light ${props.selected ? 'product-selected' : ''}`}
            >
                <div className="product-title">
                    <h2>{props.title}</h2>
                    <Icon
                        src={props.selected ? selectedRadioButton : unselectedRadioButton}
                        onClick={handleOnClick}
                    />
                </div>
                <div className="product-body">
                    <ul className="product-summary">
                        {props.summary.map((entry) => (
                            <li className="product-summary-entry" key={entry}>
                                <Icon src={check} /> <p>{entry}</p>
                            </li>
                        ))}
                    </ul>
                </div>
            </Card>
            {props.selected && ReactDOM.createPortal(
                <Card className="detailed-product-card">
                    <div className="product-img"><img src={props.img} width="100%" /></div>
                    <div className="product-content">
                        <p>{props.description}</p>
                        <h2>{props.price}</h2>
                        <Button>Hire now</Button>
                    </div>
                </Card>,
                document.getElementById('selected-product-details')
            )}
        </React.Fragment>
    );
    return props.summary.length > 0 ? extraProduct : defaultProduct;
}

export default Product;