import React, { useState } from 'react';

import selectedRadioButton from '../../../assets/icons/selectedRadioButton';
import unselectedRadioButton from '../../../assets/icons/unselectedRadioButton';

import Icon from '../Icon';

function RadioButton(props) {
    const classes = 'custom-radio-button ' + props.className;
    const radioButtonIcon = props.selected ? selectedRadioButton : unselectedRadioButton;
    const cursor = props.disabled ? 'default' : 'pointer';

    const handleOnClick = () => {
        if (!props.disabled && props.onSelectOption)
            props.onSelectOption(props.value, 'radio-button');
    };

    return (
        <span className={classes} onClick={handleOnClick} style={{ cursor }}>
            <Icon src={radioButtonIcon} /> {props.children}
        </span>
    );
}

export default RadioButton;