import { createSlice, current } from '@reduxjs/toolkit';

const initialProjectsState = {
    currentProject: null,
    currentProjectSaved: true,
};
const projectsSlice = createSlice({
    name: 'Projects',
    initialState: initialProjectsState,
    reducers: {
        setCurrentProject(state, action) {
            state.currentProject = action.payload;
            state.currentProjectSaved = true;
        },
        updateCurrentProjectScopeField(state, action) {
            const { scopeID, sectionID, questionID, value, index } = action.payload;
            if (scopeID === 1)
                if (state.currentProject.ghg_inventory.scopes[scopeID][sectionID] instanceof Array)
                    state.currentProject.ghg_inventory.scopes[scopeID][sectionID][index][questionID] = value;
                else
                    state.currentProject.ghg_inventory.scopes[scopeID][sectionID][questionID] = value;
            else
                state.currentProject.ghg_inventory.scopes[scopeID][questionID] = value;
            state.currentProjectSaved = false;
        },
        pushCurrentProjectScopeSection(state, action) {
            const { scopeID, sectionID, sectionInitialContent } = action.payload;
            state.currentProject.ghg_inventory.scopes[scopeID][sectionID].push(sectionInitialContent);
        },
        updateCurrentProjectField(state, action) {
            const { section, fieldID, value } = action.payload;
            state.currentProject.ghg_inventory[section][fieldID] = value;
            state.currentProjectSaved = false;
        }
    }
});

export const projectsActions = projectsSlice.actions;
export default projectsSlice.reducer;