import React from 'react';
import { useNavigate } from 'react-router';

import './AddProject.scss';

import Button from '../UI/Button';
import Modal from '../UI/Containers/Modal';
import NewProjectForm from './NewProjectForm';

import useBoolean from '../../hooks/use-boolean';

function AddProject(props) {
    const navigate = useNavigate();
    const { boolean: isModalOpen, setTrue: openModal, setFalse: closeModal } = useBoolean();

    const handleOnSuccess = (projectID) => {
        closeModal();
        navigate(`/inventories/${projectID}`);
    };

    return (
        <div className="add-project">
            {isModalOpen && 
            <Modal
                title="Add new project"
                onDismiss={closeModal}
                footer={<React.Fragment />}
            >
                <NewProjectForm
                    potentialOwners={props.potentialOwners}
                    onSuccess={handleOnSuccess}
                />
            </Modal>}
            <Button onClick={openModal}>Add Inventory</Button>
        </div>
    );
}

export default AddProject;