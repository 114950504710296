import chart from '../../assets/icons/chart.svg';
import dashboard from '../../assets/icons/dashboard.svg';
import documents from '../../assets/icons/documents.svg';
import flag from '../../assets/icons/flag.svg';
import lens from '../../assets/icons/lens.svg';
import products from '../../assets/icons/products.svg';
import refresh from '../../assets/icons/refresh.svg';
import reports from '../../assets/icons/reports.svg';
import share from '../../assets/icons/share.svg';
import user from '../../assets/icons/user.svg';
import mail from '../../assets/icons/mail.svg';
import settings from '../../assets/icons/settings.svg';
import plus from '../../assets/icons/plus.svg';
import plus_2 from '../../assets/icons/plus_2.svg';
import check from '../../assets/icons/check.svg';
import notifications_pending from '../../assets/icons/notifications_pending.svg';
import user_dark from '../../assets/icons/user_dark.svg';
import cross from '../../assets/icons/cross.svg';
import checkedCheckbox from '../../assets/icons/checkedCheckbox.svg';
import uncheckedCheckbox from '../../assets/icons/uncheckedCheckbox.svg';
import eye from '../../assets/icons/eye.svg';
import pencil from '../../assets/icons/pencil.svg';
import download from '../../assets/icons/download.svg';
import left_arrow from '../../assets/icons/left_arrow.svg';
import arrow_up from '../../assets/icons/arrow_up.svg';
import arrow_down from '../../assets/icons/arrow_up.svg';
import info from '../../assets/icons/info.svg';
import graphic from '../../assets/icons/graphic.svg';
import rectangles_1 from '../../assets/icons/rectangles_1.svg';
import three_dots from '../../assets/icons/three_dots.svg';
import horizontal_three_dots from '../../assets/icons/horizontal_three_dots.svg';
import building from '../../assets/icons/building.svg';
import file from '../../assets/icons/file.svg';

// TODO: find a better way of accessing icons
// TODO: make icons class accessible from parent components

export default {
    chart,
    dashboard,
    documents,
    flag,
    lens,
    products,
    refresh,
    reports,
    share,
    user,
    mail,
    settings,
    plus,
    plus_2,
    check,
    notifications_pending,
    user_dark,
    cross,
    checkedCheckbox,
    uncheckedCheckbox,
    eye,
    pencil,
    download,
    left_arrow,
    arrow_up,
    arrow_down,
    info,
    graphic,
    rectangles_1,
    three_dots,
    horizontal_three_dots,
    building,
    file
}