import React from 'react';

import Modal from '../UI/Containers/Modal';
import Button from '../UI/Button';
import NewUserForm from './NewUserForm';

import useBoolean from '../../hooks/use-boolean';

function AddUser(props) {
    const { boolean: isModalOpen, setTrue: openModal, setFalse: closeModal } = useBoolean();
    const { boolean: isSuccessModalOpen, setTrue: openSuccessModal, setFalse: closeSuccessModal } = useBoolean();

    const handleOnSuccess = () => {
        closeModal();
        openSuccessModal();
    };

    return (
        <div className='add-user'>
            {isModalOpen && 
            <Modal
                title="Add new user"
                onDismiss={closeModal}
                footer={<React.Fragment />}
            >
                <NewUserForm
                    companies={props.companies}
                    onSuccess={handleOnSuccess}
                />
            </Modal>}
            <Button onClick={openModal}>Add new user</Button>
            {isSuccessModalOpen &&
            <Modal
                title="New user added succesfully"
                width={30}
                onDismiss={closeSuccessModal}
            >
                This new user will receive an email to confirm his identity and end the Sign Up.
            </Modal>}
        </div>
    );
}

export default AddUser;