import React, { useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Label from '../UI/Text/Label';
import TextInput from '../UI/Inputs/TextInput';
import Select from '../UI/Inputs/Select';
import Button from '../UI/Button';

import useAxios from '../../hooks/use-axios';
import { usersActions } from '../../store/users-slice';

function NewUserForm(props) {
    const dispatch = useDispatch();
    const nameRef = useRef();
    const lastNameRef = useRef();
    const emailRef = useRef();
    const titleRef = useRef();
    const roleRef = useRef();
    const companyRef = useRef();
    const { isLoading, error, sendRequest: createUser } = useAxios();

    const roles = useSelector((state) => state.constants.roles);

    const handleOnSubmit = (event) => {
        event.preventDefault();
        const createUserRequest = {
            url: '/users',
            method: 'post',
            params: {
                user: {
                    name: nameRef.current.value,
                    last_name: lastNameRef.current.value,
                    email: emailRef.current.value,
                    title: titleRef.current.value,
                    role: roleRef.current.value,
                    company_id: companyRef.current.value,
                }
            }
        };
        createUser(createUserRequest, (data) => {
            if (data.success === 'true') {
                // TODO: fix company not being properly added (it does not show after creating user)
                dispatch(usersActions.addUser(data.user));
                props.onSuccess();
            } else {
                // TODO: handle this case
            }
        });
    }

    return (
        <form onSubmit={handleOnSubmit}>
            <div className="add-user-inputs">
                <div className="add-user-input">
                    <Label htmlFor="name-input">Name*</Label>
                    <TextInput
                        id="name-input"
                        placeholder="Type the user name"
                        inputRef={nameRef}
                    />
                </div>
                <div className="add-user-input">
                    <Label htmlFor="last-name-input">Last name*</Label>
                    <TextInput
                        id="last-name-input"
                        placeholder="Type the user last name"
                        inputRef={lastNameRef}
                    />
                </div>
                <div className="add-user-input">
                    <Label htmlFor="email-input">Email*</Label>
                    <TextInput
                        id="email-input"
                        placeholder="Type user's email"
                        inputRef={emailRef}
                    />
                </div>
            </div>

            <div className="add-user-inputs">
                <div className="add-user-input">
                    <Label htmlFor="title-input">Title*</Label>
                    <TextInput
                        id="title-input"
                        placeholder="Type user's title"
                        inputRef={titleRef}
                    />
                </div>
                <div className="add-user-input">
                    <Label htmlFor="role-input">Role*</Label>
                    <Select
                        id="role-input"
                        placeholder="Select a role"
                        options={roles}
                        selectRef={roleRef}
                    />
                </div>
                <div className="add-user-input">
                    <Label htmlFor="company-input">Company*</Label>
                    <Select
                        id="company-input"
                        placeholder="Select a company"
                        options={props.companies}
                        keys={['name', 'id']}
                        selectRef={companyRef}
                    />
                </div>
            </div>

            <div className="add-user-submit">
                <p>*This field is mandatory</p>
                <Button type='submit'>Save</Button>
            </div>
        </form >
    )
}

export default NewUserForm;