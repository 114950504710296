import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import plus_2 from '../../assets/icons/plus_2.svg';

import Button from '../UI/Button';
import Icon from '../UI/Icon';
import Card from '../UI/Containers/Card';
import TextInput from '../UI/Inputs/TextInput';

import useAxios from '../../hooks/use-axios';
import useBoolean from '../../hooks/use-boolean';

function AddTaskCategory() {
    const navigate = useNavigate();
    const newCategoryRef = useRef();
    const { sendRequest: createTaskCategory } = useAxios();
    const { boolean: dropdown, toggle: toggleDropdown } = useBoolean();

    const addTaskCategory = () => {
        const createTaskCategoryRequest = {
            url: '/task_categories',
            method: 'post',
            params: { task_category: { name: newCategoryRef.current.value } },
        };
        createTaskCategory(createTaskCategoryRequest, (data) => {
            navigate(`/tasks/${data.name}`);
        });
    };

    return (
        <div className="add-task-category">
            <Button
                className="custom-button add-task-category-button"
                onClick={toggleDropdown}
            >
                <Icon src={plus_2} />
                <p>Add new Category</p>
            </Button>
            {dropdown && (
                <Card className="add-task-category-card">
                    <TextInput
                        className="new-category-name-input"
                        placeholder="Enter the category name"
                        inputRef={newCategoryRef}
                    />
                    <div className="add-task-category-actions">
                        <Button onClick={addTaskCategory}>
                            Save
                        </Button>
                        <Button
                            className="custom-button-secondary"
                            onClick={toggleDropdown}
                        >
                            Cancel
                        </Button>
                    </div>
                </Card>
            )}
        </div>
    );
}

export default AddTaskCategory;