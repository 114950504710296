import { createSlice } from '@reduxjs/toolkit';

// TODO: retrieve constants from the backend
const initialConstantsState = {
    roles: [
        { name: 'Super Admin', value: 'super_admin' },
        { name: 'Company Admin', value: 'company_admin' },
        { name: 'Employee', value: 'employee' },
    ],
    scopes: ['Scope 1', 'Scope 2', 'Scope 3'],
    offsettingProjectStatuses: [
        { name: 'Issued', value: 'issued' },
        { name: 'Future', value: 'future' }
    ],
};
const constantsSlice = createSlice({
    name: 'constants',
    initialState: initialConstantsState,
    reducers: {
        saveConstants(state, action) {
            state.roles = action.payload.roles;
            state.scopes = action.payload.scopes;
            state.offsettingProjectStatuses = action.payload.offsettingProjectStatuses;
        },
    }
});

export const constantsActions = constantsSlice.actions;
export default constantsSlice.reducer;