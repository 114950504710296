import icons from '../components/UI/icons';

import { flattenArrayBasedOn } from '../tools/array-helper';

// TODO: should this be moved to a constants.js file?
export const menuTabNames = {
    products: 'Products',
    trading: 'Trading Platform',
    offsettings: 'Offsettings Projects',
    ira: 'IRA Tax Credits',
    inventories: 'Inventory',
    tasks: 'Task Manager',
    dashboard: 'Dashboard',
    users: 'Users',
    companies: 'Companies',
}

/*
    Each entry represents a Tab in the main navigation bar.
        -> name: Title that will be shown in the page referenced by the tab
            (unless title is different from tab name by specifying it under 'pageTitle')
        -> link: Path for the tab
        -> icon: Icon to be shown next to the 'name'
        -> pageTitle: Title to be shown in the top of the page ('name' will be used if no value is provided)
        -> keepTitle: Let the app know whether to show the title given by 'name'
            in the tab sub-pages (e.g. show 'Task Manager' in both /tasks and /tasks/<category>)
*/
export const menuTabs = [
    { name: menuTabNames.products, link: '/products', icon: icons.products },
    { name: menuTabNames.trading, icon: icons.graphic, children: [
        { name: menuTabNames.offsettings, link: '/offsetting_projects', pageTitle: 'Offsettings projects' },
        { name: menuTabNames.ira, link: '/ira_tax_credits', pageTitle: 'IRA Tax Credits' },
    ]},
    { name: menuTabNames.inventories, link: '/inventories', icon: icons.documents },
    { name: menuTabNames.tasks, link: '/tasks', icon: icons.rectangles_1, keepTitle: true },
    { name: menuTabNames.dashboard, link: '/dashboard', icon: icons.dashboard },
    { name: menuTabNames.users, link: '/users', icon: icons.user },
    { name: menuTabNames.companies, link: '/companies', icon: icons.building },
];

const flattenedMenuTabs = flattenArrayBasedOn(menuTabs, 'children')

export function identifyCurrentTab() {
    const tab = flattenedMenuTabs.find(
        (tab) => (window.location.pathname.includes(tab.link))
    );

    return tab?.name || '';
}

export function getPageTitle(tabName) {
    if (!tabName || tabName === '') return '';

    const tab = flattenedMenuTabs.find(
        (tab) => (tab.name === tabName)
    );
    if (window.location.pathname.replace(/\/$/, '') === tab.link)
        return tab.pageTitle || tabName;

    return tab.keepTitle ? tab.pageTitle || tabName : '';
}