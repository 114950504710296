import React from 'react';
import ReactDOM from 'react-dom';

import cross from '../../../assets/icons/cross.svg';

import Icon from '../Icon';
import Card from './Card';
import Button from '../Button';

// TODO: Make modal width editable
function Modal(props) {
    const customWidthStyle = props.width && {
        width: `${props.width}%`,
        left: `${(100 - props.width) / 2}%`,
    };

    const defaultHeader = (
        <React.Fragment>
            <h2>{props.title || ''}</h2>
            <Icon className='modal-header-cross' src={cross} onClick={props.onDismiss} />
            {props.title && <hr />}
        </React.Fragment>
    );
    const defaultFooter = (
        <React.Fragment>
            <Button onClick={props.onDismiss}>{props.dismiss || 'Ok'}</Button>
            {props.onSubmit && <Button onClick={props.onSubmit}>{props.submit || 'Save'}</Button>}
        </React.Fragment>
    );

    const backdrop = <div className='backdrop' onClick={props.onDismiss} />;
    const modal = (
        <Card
            className='modal'
            divRef={props.modalRef}
            style={customWidthStyle || {}}
        >
            <header className='modal-header'>
                {props.header || defaultHeader}
            </header>
            <div className='modal-body'>
                {props.children}
            </div>
            <footer className='modal-footer'>
                {props.footer || defaultFooter}
            </footer>
        </Card>
    );

    return (
        <React.Fragment>
            {ReactDOM.createPortal(
                backdrop,
                document.getElementById('backdrop-root')
            )}
            {ReactDOM.createPortal(
                modal,
                document.getElementById('overlay-root')
            )}
        </React.Fragment>
    );
}

export default Modal;