import React from 'react';
import { useNavigate } from 'react-router-dom';

import plus_2 from '../../assets/icons/plus_2.svg';

import Modal from '../UI/Containers/Modal';
import TaskForm from './TaskForm';
import Button from '../UI/Button';
import Icon from '../UI/Icon';

import useBoolean from '../../hooks/use-boolean';
import useNotification from '../../hooks/use-notification';

function AddTask(props) {
    const navigate = useNavigate();
    const { pushNotification } = useNotification();
    const { boolean: isModalOpen, setTrue: openModal, setFalse: closeModal } = useBoolean();

    const handleOnSuccess = (taskID, taskTitle) => {
        pushNotification({ message: `The task ${taskTitle} has been created` });
        navigate(`/tasks/${props.categoryName}/${taskID}`);
    };

    return (
        <React.Fragment>
            {isModalOpen && 
            <Modal
                title={`${props.categoryName} - New task`}
                onDismiss={closeModal}
                footer={<React.Fragment />}
                modalRef={props.modalRef}
            >
                <TaskForm
                    categoryID={props.categoryID}
                    potentialAssignees={props.potentialAssignees}
                    onSuccess={handleOnSuccess}
                />
            </Modal>}
            <Button
                className={props.buttonClass || "custom-button add-task-button"}
                onClick={openModal}
            >
                <Icon src={plus_2} />
                <p>Add new Task</p>
            </Button>
        </React.Fragment>
    );
}

export default AddTask;