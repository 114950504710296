import { createSlice } from '@reduxjs/toolkit';

const initialUsersState = {
    users: [],
};
const usersSlice = createSlice({
    name: 'Users',
    initialState: initialUsersState,
    reducers: {
        setUsers(state, action) {
            state.users = action.payload;
        },
        addUser(state, action) {
            state.users = [...state.users, action.payload];
        },
        updateUser(state, action) {
            state.users = state.users.map((user) => {
                if (user.id === action.payload.id)
                    return action.payload;
                return user;
            });
        },
        removeUser(state, action) {
            state.users = state.users.filter((user) => (
                user.id !== action.payload
            ));
        },
    }
});

export const usersActions = usersSlice.actions;
export default usersSlice.reducer;