import React from 'react';

import './Login.scss';

import logotipo from '../../assets/logotipo.svg';

import Icon from '../UI/Icon';
import LoginForm from './LoginForm';
import RegisterForm from './RegisterForm';

function Login() {
  return (
    <div>
      <Icon className="logotipo" src={logotipo} />
      <div className="main-container">
        <div className="sub-container">
          <LoginForm />
        </div>
        <div className="sub-container">
          <RegisterForm />
        </div>
      </div>
    </div>
  );
};

export default Login;
