import React, { useRef } from 'react';

import Label from '../../../UI/Text/Label';
import TextInput from '../../../UI/Inputs/TextInput';
import NumberInput from '../../../UI/Inputs/NumberInput';

function Subsidiary(props) {
    const companyNameRef = useRef();
    const ownershipPercentageRef = useRef();

    const handleOnChange = () => {
        props.onChange(props.index, {
            company_name: companyNameRef.current.value,
            ownership_percentage: ownershipPercentageRef.current.value,
        });
    }

    return (
        <div className="organizational-limits-inputs">
            <div className="organizational-limits-input">
                    <Label>Company Name</Label>
                    <TextInput
                        inputRef={companyNameRef}
                        value={props.companyName || ''}
                        debouncingTime={800}
                        onChange={handleOnChange}
                    />
                </div>
                <div className="organizational-limits-input">
                    <Label>Ownership Percentage*</Label>
                    <NumberInput
                        inputRef={ownershipPercentageRef}
                        value={props.ownershipPercentage || ''}
                        category="percentage"
                        debouncingTime={800}
                        onChange={handleOnChange}
                    />
                </div>
        </div>
    );
}

export default Subsidiary;