
import React, { useRef, useState } from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';

import './TaskForm.scss';

import Label from '../UI/Text/Label';
import TextInput from '../UI/Inputs/TextInput';
import TextArea from '../UI/Inputs/TextArea';
import Select from '../UI/Inputs/Select';
import Button from '../UI/Button';

import useAxios from '../../hooks/use-axios';
import { parseDate, dateFormats } from '../../tools/date-helper';

function TaskForm(props) {
    const [error, setError] = useState(null);
    const titleRef = useRef();
    const descriptionRef = useRef();
    const assigneeIDRef = useRef();
    const deliveryDateRef = useRef();
    const { sendRequest: createOrUpdateTask } = useAxios();
    const task = props.task || {};

    const handleOnSubmit = (event) => {
        event.preventDefault();
        const createOrUpdateTaskRequest = {
            url: `/tasks/${props.updateMode ? task.id : ''}`,
            method: 'post',
            params: {
                task: {
                    title: titleRef.current.value,
                    description: descriptionRef.current.value,
                    assignee_id: assigneeIDRef.current.value,
                    delivery_date: deliveryDateRef.current.value,
                    task_category_id: props.categoryID,
                }
            },
            manageResponse: true,
        };
        createOrUpdateTask(createOrUpdateTaskRequest, (data) => {
            if (data.success === 'true')
                props.onSuccess(data.task_id, data.task_title);
            else
                setError(data.reason); // TODO: show details of why the task could not be created/updated
        });
    }

    return (
        <form onSubmit={handleOnSubmit} className="task-form">
            <div className="task-form-inputs">
                <div className="task-form-input">
                    <Label htmlFor="title-input">Title*</Label>
                    <TextInput
                        id="title-input"
                        placeholder="Type the task title"
                        inputRef={titleRef}
                        value={task.title}
                    />
                </div>
            </div>
            <div className="task-form-inputs">
                <div className="task-form-description-input">
                    <Label htmlFor="description-input">Task Description</Label>
                    <TextArea
                        id="name-input"
                        name="description"
                        placeholder="Type the task description..."
                        inputRef={descriptionRef}
                        maxLength="900"
                        value={task.description}
                    />
                </div>
            </div>
            <div className="task-form-inputs">
                <div className="task-form-input">
                    <Label htmlFor="assignee-input">Assign to*</Label>
                    <Select
                        id="assignee-input"
                        placeholder="Select an employee"
                        options={props.potentialAssignees || []}
                        keys={['full_name', 'id']}
                        selectRef={assigneeIDRef}
                        value={task.assignee?.id}
                    />
                </div>
                <div className="task-form-input">
                    <Label htmlFor="delivery-date-input">Delivery Date*</Label>
                    <LocalizationProvider dateAdapter={AdapterLuxon}>
                        <DatePicker
                            id="delivery-date-input"
                            format={dateFormats['en_gb']}
                            inputRef={deliveryDateRef}
                            value={parseDate(task.delivery_date)}
                        />
                    </LocalizationProvider>
                </div>
            </div>

            {error && <p className="task-form-error">{error}</p>}

            <div className="task-form-submit">
                <p>*This field is mandatory</p>
                <Button type='submit'>{props.updateMode ? 'Update task' : 'Create task'}</Button>
            </div>
        </form>
    );
}

export default TaskForm;