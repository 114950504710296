import React from 'react';

import Modal from '../../../UI/Containers/Modal';

function IntroductionModal(props) {
    return (
        <Modal
            onDismiss={props.onDismiss}
            footer={<React.Fragment />}
        >
            <div className="standard-ghg-body">
                <p className="introduction-header">
                    Calculating HFC and PFC Emissions from the Manufacturing, Servicing, and/or Disposal of Refrigeration and Air-Conditioning Equipment
                </p>
                <ul>
                    <li className="bullet-list-item">
                        Purpose and Domain of Application
                    </li>
                </ul>
                <p className="standard-ghg-text">
                    This guideline is written for plant managers and site personnel to facilitate the measurement and reporting of greenhouse gas direct hydrofluorocarbon (HFC) and perfluorocarbon (PFC) emissions resulting from manufacturing, servicing, and disposal of refrigeration and air-conditioning equipment.  Direct HFC emissions occur from sources that are owned or controlled by the company. A step-by-step approach is presented to cover every phase of the calculation process from data gathering to reporting. <br /><br />This sector guideline should be applied to projects whose operations involve the manufacture, use, and disposal of refrigeration and air-conditioning equipment.
                </p>
                <ul>
                    <li className="bullet-list-item">
                        Process Description and Assumptions
                    </li>
                </ul>
                <p className="standard-ghg-text">
                    Refrigeration and air-conditioning is composed of many end-uses, including household refrigeration, domestic air conditioning and heat pumps, mobile air conditioning, chillers, retail food refrigeration, cold storage warehouses, refrigerated transport, industrial process refrigeration, and commercial unitary air conditioning systems. Historically, this sector has used various ozone-depleting substances (ODS) such as CFCs and HCFCs as refrigerants. These ODS are being phased out under the Montreal Protocol and are being replaced with HFCs and PFCs. <br /><br />HFC and PFC emissions from the refrigeration and air conditioning sector result from the manufacturing process, from leakage over the operational life of the equipment, and from disposal at the end of the useful life of the equipment. These gases have 100-year global warming potentials (GWP) 140 to 11,700 times that of carbon dioxide, so their potential impact on climate change can be significant (Table 1). <br /><br />By the same token, any reductions of these gases can have a large potential benefit.This protocol addresses emissions from manufacturing, operation, and disposal phases.
                </p>
            </div>
        </Modal>
    );
}

export default IntroductionModal;