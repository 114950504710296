import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './Companies.scss';

import CompaniesTable from './CompaniesTable';
import CompaniesFilters from './CompaniesFilters';
import AddCompany from './AddCompany';

import useAxios from '../../hooks/use-axios';

import { companiesActions } from '../../store/companies-slice';
import { getArrayPage, totalPages } from '../../tools/pagination-helper';

function Companies() {
    const dispatch = useDispatch();
    const companies = useSelector((state) => state.companies.companies);
    const [page, setPage] = useState(1);
    const [filters, setFilters] = useState({ keyword: '' });
    const { isLoading, error, sendRequest: fetchCompanies } = useAxios();
    const perPage = 5; // TODO: make this field configurable and store it in the store

    useEffect(() => {
        const companiesIndexRequest = {
            url: '/companies_index',
            params: {
                filters,
            }
        };
        fetchCompanies(companiesIndexRequest, (data) => {
            dispatch(companiesActions.setCompanies(data.companies));
        });
    }, [fetchCompanies, filters]);

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    const handleFiltersChange = (newFilters) => {
        setFilters((oldFilters) => ({ ...oldFilters, ...newFilters }));
    }

    return (
        <React.Fragment>
            <AddCompany companies={companies} />

            <CompaniesFilters
                onFiltersChange={handleFiltersChange}
                companies={companies}
            />
            
            <CompaniesTable
                companies={getArrayPage(companies, page, perPage)}
                loading={isLoading}
                error={error}
                page={page}
                pages={totalPages(companies.length, perPage)}
                onPageChange={handlePageChange}
            />
        </React.Fragment>
    )
}

export default Companies;