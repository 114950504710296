import React from 'react';
import { useDispatch } from 'react-redux';

import cross from '../../../assets/icons/cross.svg'; 

import Icon from '../Icon';

import { notificationsActions } from '../../../store/notifications-slice';

function Notification(props) {
    const dispatch = useDispatch();

    const removeNotification = () => {
        dispatch(notificationsActions.removeNotification(props.index));
    };

    return (
        <React.Fragment>
            <p>{props.message}</p>
            <Icon src={cross} onClick={removeNotification} />
        </React.Fragment>
    );
}

export default Notification;