import React from 'react';


import Modal from '../UI/Containers/Modal';
import Card from '../UI/Containers/Card';
import Label from '../UI/Text/Label';

function SuccessModal(props) {
    // TODO: show status after being converted from Statuses constant (e.g. 'Issued' instead of 'issued')
    return (
        <Modal
            title="Project created"
            width={30}
            onDismiss={props.onDismiss}
        >
            <Card className="success-modal-card">
                <Label>Credit Quantity</Label>
                <p>{props.project.amount}</p>

                <Label>Price</Label>
                <p>{props.project.price}</p>

                <Label>Standard</Label>
                <p>{props.project.standard}</p>

                <Label>Status</Label>
                <p>{props.project.status}</p>
            </Card>
        </Modal>
    );
}

export default SuccessModal;