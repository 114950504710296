import React, { useState } from 'react';

import Tab from './Tab';
import Card from '../Containers/Card';

function Tabs(props) {
    const [activeTab, setActiveTab] = useState(props.tabs[0]);
    const contentContainerClasses = props.contentContainerClasses || '';

    const onTabClick = (tabName) => {
        setActiveTab(props.tabs.find((tab) => (tab.name === tabName)));
    };

    return (
        <React.Fragment>
            <div className="tabs">
                {props.tabs.map((tab) => (
                    <Tab
                        name={tab.name}
                        key={tab.name}
                        onTabClick={onTabClick}
                        active={tab.name === activeTab.name}
                    />
                ))}
            </div>
            <Card className={contentContainerClasses}>
                {activeTab.content}
            </Card>
        </React.Fragment>
    );
}

export default Tabs;