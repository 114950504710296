import { routes, routeNames } from './router-helper';
import { menuTabs, menuTabNames } from './ui-helper';

// TODO: read role values from a constant instead of hardcoding them
// The first route in the array will be used for redirecting the user after login
const userRoutes = {
    'Super admin': [
        routeNames.companies, routeNames.users,
    ],
    'Company admin': [
        routeNames.products,
        routeNames.users,
        routeNames.inventories, routeNames.inventoryShow,
        routeNames.tasks, routeNames.taskCategory, routeNames.taskShow,
        routeNames.offsettingProjects, routeNames.taxCredits,
        routeNames.dashboard
    ],
    'Employee': [
        routeNames.inventories, routeNames.inventoryShow,
        routeNames.tasks, routeNames.taskCategory, routeNames.taskShow,
        routeNames.dashboard
    ],
};

// TODO: read role values from a constant instead of hardcoding them
const userTabs = {
    'Super admin': [
        menuTabNames.users, menuTabNames.companies
    ],
    'Company admin': [
        menuTabNames.products,
        menuTabNames.trading,
        menuTabNames.inventories,
        menuTabNames.tasks,
        menuTabNames.dashboard,
        menuTabNames.users,
    ],
    'Employee': [
        menuTabNames.inventories,
        menuTabNames.tasks,
        menuTabNames.dashboard,
    ],
};

export function currentUserRoutes(role) {
    if (!role) return [];

    return routes.filter((route) => (
        userRoutes[role].includes(route.name)
    ));
}

export function initialRoutePath(role) {
    return currentUserRoutes(role).find((route) => (
        route.name === userRoutes[role][0]
    )).path;
}

export function currentUserTabs(role) {
    if (!role) return [];

    return menuTabs.filter((tab) => (
        userTabs[role].includes(tab.name)
    ));
}