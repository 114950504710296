// TODO: take to debate if the name of this file should be changed

const externalComponents = [
    'MuiPickersDay',
];

// For some reason some components (like MUI datepicker) don´t pass the refs properly,
// which causes that calling node.contains method does not detect the event.target.
// Until a better approach is found, externalComponents should store the class of those
// elements with this issue
export function isEventFromExternalComponent(event) {
    try {
        return externalComponents.some(
            (component) => (event.target.className?.includes(component))
        );
    } catch {
        return false;
    }
}